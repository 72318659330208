/* eslint-disable import/prefer-default-export */
import API from './api';

export const getLogin = params => API.post(`${process.env.REACT_APP_LOGIN}/login`, params);

export const getEscopoUsuario = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/escopo-usuario`, params);

export const getEscopoAmbiente = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/escopo-ambiente`);
