import React, { useState, useEffect } from 'react';
import { Grid, InputAdornment, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import InputMain from '../../../shared/InputMain/InputMain';
import SelectMain from '../../../shared/InputMain/SelectMain';
import CheckBoxMain from '../../../shared/InputMain/CheckBoxMain';
import ButtonMain from '../../../shared/ButtonMain';
import { segmentosEmpresa, faturamentosAnual } from '../../../shared/Enums/EnumsOnboarding';
import validaCaracteresEspeciais from '../../../../../utils/validaCaracteresEspeciais';
import strip from '../../../../../utils/strip';
import { formatarData, unmaskValue } from '../../../../../utils/format';
import { postCadastroDadosEmpresa } from '../../../../../api/onboarding';
import AlertErroMain from '../../../shared/AlertErroMain';
import { getConfigAmbiente } from '../../../../../shared';

export default ({
  mudarAba,
  todosDadosCadastrais,
  setTodosDadosCadastrais,
  isPendingCadastro,
  setPendingCadastro,
  origemProposta
}) => {
  const [dadosOnboarding, setDadosOnboarding] = useState(false);
  const [mostraErro, setMostraErro] = useState(false);

  const configAmbiente = getConfigAmbiente();
  const link_termos_de_uso =
    configAmbiente && configAmbiente.documentos && configAmbiente.documentos.termos_e_condicoes_de_uso
      ? configAmbiente.documentos.termos_e_condicoes_de_uso
      : null;
  const link_aviso_de_privacidade =
    configAmbiente && configAmbiente.documentos && configAmbiente.documentos.aviso_de_privacidade
      ? configAmbiente.documentos.aviso_de_privacidade
      : null;

  useEffect(() => {
    if (todosDadosCadastrais.cnpj === undefined) return false;
    if (dadosOnboarding) return false;

    var dadosOnboardingTemp = { cnpj: todosDadosCadastrais.cnpj, ...todosDadosCadastrais.DadosEmpresa };
    dadosOnboardingTemp = controlarDadosFomulario(dadosOnboardingTemp);

    setDadosOnboarding(dadosOnboardingTemp);
  }, [todosDadosCadastrais]);

  const controlarDadosFomulario = dados => {
    if (dados.segmento_id !== undefined)
      dados.segmento_id = segmentosEmpresa[dados.segmento_id] !== undefined ? dados.segmento_id : '';

    if (dados.faturamento_anual !== undefined) {
      if (origemProposta === 'connect' || origemProposta === 'indicantes') {
        dados.faturamento_anual = unmaskValue(dados.faturamento_anual);
      } else {
        dados.faturamento_anual =
          faturamentosAnual[dados.faturamento_anual] !== undefined ? dados.faturamento_anual : '';
      }
    }

    if (dados.data_fundacao !== undefined) dados.data_fundacao = formatarData(dados.data_fundacao);

    if (dados.ddd !== undefined && dados.fone !== undefined) dados.fone = dados.ddd + '' + dados.fone;

    if (
      dados.ddd_fone_contato !== undefined &&
      dados.fone_contato !== undefined &&
      dados.ddd_fone_contato !== null &&
      dados.fone_contato !== null
    ) {
      dados.fone_contato = dados.ddd_fone_contato + '' + dados.fone_contato;
    } else {
      dados.fone_contato = '';
    }

    if (dados.autoriza_consulta !== undefined)
      dados.autoriza_consulta = dados.autoriza_consulta === 'S' ? true : false;

    if (dados.politica_privacidade)
      dados.politica_privacidade = dados.politica_privacidade === 'S' ? true : false;

    return dados;
  };

  const formatarPayLoad = dadosForm => {
    dadosForm.cnpj = dadosOnboarding.cnpj;
    dadosForm.fone = strip(dadosForm.fone);
    dadosForm.ddd = dadosForm.fone.substr(0, 2);
    dadosForm.fone = dadosForm.fone.substr(2);
    dadosForm.fone_contato = strip(dadosForm.fone_contato);
    dadosForm.ddd_fone_contato = dadosForm.fone_contato.substr(0, 2);
    dadosForm.fone_contato = dadosForm.fone_contato.substr(2);
    dadosForm.data_fundacao = formatarData(dadosForm.data_fundacao);
    dadosForm.autoriza_consulta = dadosForm.autoriza_consulta ? 'S' : 'N';
    dadosForm.politica_privacidade = dadosForm.politica_privacidade ? 'S' : 'N';
    if (origemProposta === 'connect' || origemProposta === 'indicantes') {
      dadosForm.faturamento_anual = unmaskValue(dadosForm.faturamento_anual);
    }
    return dadosForm;
  };

  const handleFormSubmit = (values, form) => {
    var dadosForm = { ...values };
    dadosForm = formatarPayLoad(dadosForm);

    setPendingCadastro(true);
    postCadastroDadosEmpresa(dadosForm)
      .then(() => {
        var dadosCad = { ...todosDadosCadastrais };
        dadosCad.DadosEmpresa = dadosForm;
        setTodosDadosCadastrais(dadosCad);

        setPendingCadastro(false);
        form.setSubmitting(false);

        mudarAba('DadosEndereco');
      })
      .catch(err => {
        setMostraErro(err.response);
      })
      .finally(() => {
        setPendingCadastro(false);
        form.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={dadosOnboarding}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        cnpj: Yup.string(),
        nome: Yup.string()
          .nullable()
          .required('Favor informar a razão social da empresa')
          .test('nome', 'Razão social invalida', value => {
            if (value && value.toString().replace(/\s/g, '') === '') {
              return false;
            } else if (value && value.length > 0) {
              value = value.trim();
              return value.includes(' ');
            }
            return true;
          })
          .test('nome', 'Caracteres Inválidos', value => {
            let caracteresEspeciais = [`'`, `*`, `"`];
            return validaCaracteresEspeciais(value, caracteresEspeciais);
          }),
        data_fundacao: Yup.string()
          .nullable()
          .required('Favor informar a data de criação da empresa')
          .test({
            message: () => {
              return 'Data de Criação inválida';
            },
            name: 'data_fundacao',
            test: data => {
              if (data !== undefined && data !== '' && data.length >= 10) {
                data = data.substr(0, 10);
                let dia = data.split('/')[0];
                let mes = data.split('/')[1];
                let ano = data.split('/')[2];
                let dataAtual = new Date(ano, mes - 1, dia);
                if (
                  dataAtual.getMonth() + 1 !== parseInt(mes) ||
                  dataAtual.getDate() !== parseInt(dia) ||
                  dataAtual.getFullYear() !== parseInt(ano) ||
                  dataAtual > new Date()
                ) {
                  return false;
                }
                if (dataAtual.getFullYear() < 1500) {
                  return false;
                }
                return true;
              }
              return false;
            }
          }),
        faturamento_anual: Yup.string()
          .nullable()
          .test('faturamento_anual', 'Favor fornecer o faturamento anual da empresa', value => {
            return value !== '' && value !== null && value !== undefined && value !== 0 && value !== '0';
          }),
        segmento_id: Yup.string()
          .nullable()
          .test('segmento_id', 'Favor escolher a natureza jurídica da empresa', value => {
            return value !== '' && value !== null && value !== undefined;
          }),
        email: Yup.string()
          .nullable()
          .required('Favor informar o e-Mail da empresa'),
        fone: Yup.string()
          .nullable()
          .required('Favor informar o telefone celular da empresa')
          .test({
            message: 'Telefone celular inválido',
            test: value => {
              if (value !== '' && value !== null && value !== undefined) {
                let tel = strip(value);
                let ddd = parseInt(tel.substring(0, 2), 10);
                let telWithoutDDD = tel.substring(2, tel.length);

                if (ddd <= 10 || ddd >= 100) {
                  return false;
                }

                return telWithoutDDD.length === 9 &&
                  [7, 8, 9].includes(parseInt(telWithoutDDD.charAt(0), 10))
                  ? telWithoutDDD
                  : false;
              }
              return true;
            }
          }),
        nome_contato: Yup.string()
          .nullable()
          .required('Favor informar o nome de contato da empresa'),
        fone_contato: Yup.string()
          .nullable()
          .test({
            message: 'Telefone/Celular inválido',
            test: value => {
              if (value !== '' && value !== null && value !== undefined) {
                let tel = strip(value);
                let ddd = parseInt(tel.substring(0, 2), 10);
                let telWithoutDDD = tel.substring(2, tel.length);

                if (ddd <= 10 || ddd >= 100) {
                  return false;
                }

                return telWithoutDDD.length === 8 ||
                  (telWithoutDDD.length === 9 &&
                    [7, 8, 9].includes(parseInt(telWithoutDDD.charAt(0), 10)))
                  ? telWithoutDDD
                  : false;
              }
              return true;
            }
          }),
        autoriza_consulta: Yup.bool()
          .nullable()
          .test('autoriza_consulta', 'Favor aceitar os Termos e Condições de Uso', value => {
            if (
              origemProposta === undefined ||
              (origemProposta !== 'connect' && origemProposta !== 'indicantes')
            ) {
              return value;
            } else return true;
          }),
        politica_privacidade: Yup.bool()
          .nullable()
          .test('politica_privacidade', 'Favor aceitar as Politicas e Termos acima', value => {
            if (
              origemProposta === undefined ||
              (origemProposta !== 'connect' && origemProposta !== 'indicantes')
            ) {
              return value;
            } else return true;
          })
      })}
      onSubmit={handleFormSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      render={({ values, isSubmitting }) => {
        return (
          <Form>
            <ConnectedFocusError />
            <Field
              type="text"
              name="cnpj"
              titulo="CNPJ"
              id="cnpj"
              size="small"
              uppercase={true}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CheckIcon className="texto-success" />
                  </InputAdornment>
                )
              }}
              inputClassName="fundo-cor-cinza-claro"
              tipoMascara="CNPJMask"
            />
            <Field
              type="text"
              name="data_fundacao"
              titulo="Data da criação da empresa"
              id="data_fundacao"
              size="small"
              uppercase={true}
              component={InputMain}
              InputProps={
                dadosOnboarding.data_fundacao
                  ? {
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CheckIcon className="texto-success" />
                        </InputAdornment>
                      )
                    }
                  : { maxLength: 10 }
              }
              inputClassName={dadosOnboarding.data_fundacao ? 'fundo-cor-cinza-claro' : ''}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              tipoMascara="dateMask"
            />
            <Field
              type="text"
              name="nome"
              titulo="Razão social"
              id="nome"
              size="small"
              uppercase={true}
              component={InputMain}
              InputProps={
                dadosOnboarding.nome
                  ? {
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CheckIcon className="texto-success" />
                        </InputAdornment>
                      )
                    }
                  : {}
              }
              inputClassName={dadosOnboarding.nome ? 'fundo-cor-cinza-claro' : ''}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
            />
            <Field
              name="segmento_id"
              titulo="Natureza jurídica"
              id="segmento_id"
              size="small"
              component={SelectMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              opcoes={segmentosEmpresa}
            />
            {origemProposta === 'connect' || origemProposta === 'indicantes' ? (
              <Field
                name="faturamento_anual"
                titulo="Faturamento Anual (R$)"
                id="faturamento_anual"
                type="text"
                size="small"
                component={InputMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting}
                tipoMascara="number-money"
                inputProps={{ maxLength: 20 }}
              />
            ) : (
              <Field
                name="faturamento_anual"
                titulo="Faturamento Anual"
                id="faturamento_anual"
                size="small"
                component={SelectMain}
                autoComplete="off"
                disabled={isPendingCadastro || isSubmitting}
                opcoes={faturamentosAnual}
              />
            )}
            <Field
              type="text"
              name="fone"
              titulo="Telefone celular corporativo"
              id="fone"
              size="small"
              uppercase={true}
              inputProps={{ maxLength: 15 }}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              tipoMascara="phoneMask"
            />
            <Field
              type="email"
              name="email"
              titulo="E-mail corporativo"
              id="email"
              size="small"
              uppercase={false}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
            />
            <Typography
              variant="subtitle1"
              className="borda-bottom-1 borda-cor-cinza-escuro texto-cor-cinza-escuro pad-topbot-5 margin-top-10 font-bold"
            >
              Dados do Contato
            </Typography>
            <Field
              type="text"
              name="nome_contato"
              titulo="Nome Contato"
              id="nome_contato"
              size="small"
              uppercase={true}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              InputProps={{ maxLength: 60 }}
            />
            <Field
              type="text"
              name="fone_contato"
              titulo="Telefone ou Celular Contato"
              id="fone_contato"
              size="small"
              uppercase={true}
              inputProps={{ maxLength: 15 }}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
              tipoMascara="phoneMask"
            />
            <Field
              type="email"
              name="email_contato"
              titulo="E-mail contato"
              id="email_contato"
              size="small"
              uppercase={false}
              component={InputMain}
              autoComplete="off"
              disabled={isPendingCadastro || isSubmitting}
            />
            {(origemProposta === undefined ||
              (origemProposta !== 'connect' && origemProposta !== 'indicantes')) && (
              <>
                <Field
                  type="checkbox"
                  name="autoriza_consulta"
                  value="aceito"
                  checked={values.autoriza_consulta ? true : false}
                  component={CheckBoxMain}
                  disabled={isPendingCadastro || isSubmitting}
                  corCheckBox="checkbox-cor-complementar"
                  // eslint-disable-next-line prettier/prettier
                  controlLabelCheckbox={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <>Li e aceito os </>
                      <a href={link_termos_de_uso} target="_blank" rel="noreferrer">
                        Termos e condições de uso.
                      </a>
                    </>
                  }
                />
                <Field
                  type="checkbox"
                  name="politica_privacidade"
                  id="politica_privacidade"
                  value="aceito"
                  checked={values.politica_privacidade ? true : false}
                  component={CheckBoxMain}
                  disabled={isPendingCadastro || isSubmitting}
                  corCheckBox="checkbox-cor-complementar"
                  // eslint-disable-next-line prettier/prettier
                  controlLabelCheckbox={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <>Li e aceito a </>
                      <a href={link_aviso_de_privacidade} target="_blank" rel="noreferrer">
                        Politica de privacidade.
                      </a>
                      <> </>
                      Dou consentimento para que seja realizado a coleta e o tratamento dos meus dados
                      pessoais na forma descrita.
                    </>
                  }
                />
              </>
            )}
            <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} />

            <Grid container spacing={3} className="pad-topbot-50">
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
              />
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <ButtonMain
                  type="submit"
                  tipoBotao="principal"
                  disabled={isPendingCadastro || isSubmitting}
                >
                  Continuar
                </ButtonMain>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    />
  );
};
