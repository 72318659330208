import React, { useState, useEffect } from 'react';

import { HashRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Routes from './Routes';
import { carregarEscopoAmbienteTenant, carregarServicos } from './shared';
import LoaderMain from './components/Onboarding/shared/LoaderMain';
import Error from './components/ResponseStatus/Error';

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH
});

const App = () => {
  const [configAmbiente, setConfigAmbiente] = useState(false);
  window.dataLayer.push({
    event: 'pageview'
  });

  useEffect(() => {
    carregarEscopoAmbienteTenant(configs => {
      setConfigAmbiente(configs);
    });
  }, []);

  useEffect(() => {
    if (configAmbiente === false) return false;

    carregarServicos(configAmbiente.servicos);

    if (document && document.title && configAmbiente.sistema && configAmbiente.sistema.tenant_name)
      document.title = configAmbiente.sistema.tenant_name.toLocaleUpperCase() + ' | Plataforma Digital';

    var favicon = document ? document.getElementById('favicon') : false;
    var faviconUrl =
      configAmbiente && configAmbiente.imagens && configAmbiente.imagens.FAVICON
        ? configAmbiente.imagens.FAVICON
        : '';
    if (favicon) favicon.href = faviconUrl;
  }, [configAmbiente]);

  return (
    <>
      {configAmbiente !== false && Object.keys(configAmbiente).length > 0 ? (
        <Router>
          <div className="wrapper">
            <Routes />
          </div>
        </Router>
      ) : configAmbiente === false ? (
        <div className="wrapper">
          <LoaderMain />
        </div>
      ) : (
        <div className="wrapper">
          <Error />
        </div>
      )}
    </>
  );
};

export default App;
