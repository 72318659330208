import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, CardContent, Grid } from '@mui/material';
import { AccessTime, CheckCircle, Error } from '@mui/icons-material';
import ButtonMain from '../../../shared/ButtonMain';
import { If } from '../../../../../shared';
import {
  getDadosUsuario,
  getClienteStatus,
  getConfigAmbiente
} from '../../../../../shared/services/User';

export default ({ definirEtapa, stepFinalizacaoCadastro }) => {
  const [emailUsuario, setEmailUsuario] = useState('');
  const [clienteStatus, setClienteStatus] = useState({});
  const history = useHistory();
  const configAmbiente = getConfigAmbiente();

  const emailContato =
    (configAmbiente && configAmbiente.sistema && configAmbiente.sistema.email_suporte
      ? configAmbiente.sistema.email_suporte
      : process.env.REACT_APP_EMAIL_SUPORTE) +
    '@' +
    (configAmbiente && configAmbiente.sistema && configAmbiente.sistema.dominio_padrao_email_suporte
      ? configAmbiente.sistema.dominio_padrao_email_suporte
      : process.env.REACT_APP_MAIL_DOMINIO_PADRAO_SUPORTE);

  useEffect(() => {
    var usuario = getDadosUsuario();
    setEmailUsuario(usuario.email_escondido || '');
    var cliStatus = getClienteStatus();
    setClienteStatus(cliStatus);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepFinalizacaoCadastro]);

  const definirEtapaSolucoes = () => {
    definirEtapa('SOL');
  };

  const acessaSite = () => {
    window.location.href =
      configAmbiente && configAmbiente.sistema && configAmbiente.sistema.site_institucional
        ? configAmbiente.sistema.site_institucional
        : process.env.REACT_APP_SITELANDINGPAGE;
  };

  const acessaLogin = () => {
    history.push('/authentication/logout');
  };

  const acessaCadastroEmpresa = () => {
    history.push('/entrada-cnpj-onboarding');
  };

  return (
    <CardContent sx={{ margin: '0 0 0 -10px;' }}>
      <Grid container sx={{ p: '20px 0 130px 0' }}>
        <If test={stepFinalizacaoCadastro === 'validacao'}>
          <Grid item xl={3} lg={3} md={3} sm={2} xs={12} />
          <Grid item xl={6} lg={6} md={6} sm={8} xs={12} sx={{ p: { xl: '0 40px', lg: '0 40px' } }}>
            <div className="texto-centro">
              <AccessTime className="font-size-45 texto-info" sx={{ color: '#FEB700' }} />
            </div>

            <Typography variant="h5" className="mt-3 texto-cor-principal texto-negrito texto-centro">
              Estamos analisando os dados da empresa
            </Typography>

            <Typography
              variant="subtitle1"
              className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro fundo-cor-cinza-claro"
            >
              Solicitação enviada
              {clienteStatus &&
              clienteStatus.ppc_status &&
              clienteStatus.ppc_status.proposta_data_inserted
                ? ' em ' + clienteStatus.ppc_status.proposta_data_inserted
                : ''}
            </Typography>

            <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
              Recebemos seus dados e eles estão em análise. Em breve, você receberá uma resposta no
              e-mail
              <b>{emailUsuario}</b>
              <span>. </span>
              Não se preocupe, estamos trabalhando para atender sua solicitação o mais rápido possível.
              <br />
              Em caso de dúvidas fale com a gente via: chat, pelo telefone ou mande um e-mail para &nbsp;
              <span>
                <a href={`mailto:${emailContato}`}>{emailContato}</a>
              </span>
            </Typography>
          </Grid>
          <Grid container className="mt-3">
            <Grid item xl={3} lg={3} md={2} sm={1} xs={12} />
            <Grid item xl={6} lg={6} md={8} sm={10} xs={12}>
              <Grid container className="mt-3" spacing={3}>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
                >
                  <center>
                    <ButtonMain tipoBotao="transparente" onClick={acessaLogin}>
                      Sair
                    </ButtonMain>
                  </center>
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >
                  <center>
                    <ButtonMain tipoBotao="principal" onClick={acessaCadastroEmpresa}>
                      Cadastrar outra empresa
                    </ButtonMain>
                  </center>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </If>

        <If test={stepFinalizacaoCadastro === 'solucoes'}>
          <Grid item xl={4} lg={4} md={4} sm={2} xs={12} />
          <Grid item xl={4} lg={4} md={4} sm={8} xs={12}>
            <div className="texto-centro">
              <CheckCircle className="font-size-45 texto-info" sx={{ color: '#54A900' }} />
            </div>

            <Typography variant="h5" className="mt-3 texto-cor-principal texto-negrito texto-centro">
              Ótima notícia: os dados da empresa foram aprovados!
            </Typography>

            <Typography variant="subtitle1" className="mt-1 texto-cor-cinza-escuro pad-10 texto-centro">
              Acesse as soluções de crédito com as condições pensadas para o seu negócio.
            </Typography>

            <center className="mt-4">
              <ButtonMain tipoBotao="principal" onClick={definirEtapaSolucoes}>
                Conhecer soluções financeiras
              </ButtonMain>
            </center>
          </Grid>
        </If>

        <If test={stepFinalizacaoCadastro === 'reprovado'}>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} />
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="texto-centro">
              <Error className="font-size-45 texto-info" sx={{ color: '#FEB700' }} />
            </div>

            <Typography variant="h5" className="mt-3 texto-cor-principal texto-negrito texto-centro">
              Quem sabe na próxima vez...
            </Typography>

            <Typography variant="subtitle1" className="mt-1 texto-cor-cinza-escuro pad-10 texto-centro">
              Seu cadastro não correspondeu às políticas de crédito vigentes, mas você pode solicitar uma
              nova análise futuramente.
            </Typography>

            <Grid container className="mt-3" spacing={3}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
              >
                <center>
                  <ButtonMain tipoBotao="transparente" onClick={acessaSite}>
                    Voltar para o site
                  </ButtonMain>
                </center>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <center>
                  <ButtonMain tipoBotao="principal" onClick={acessaCadastroEmpresa}>
                    Cadastrar outra empresa
                  </ButtonMain>
                </center>
              </Grid>
            </Grid>
          </Grid>
        </If>
      </Grid>
    </CardContent>
  );
};
