import React, { useEffect, useState } from 'react';
import MD5 from 'crypto-js/md5';
import MainHeader from '../Main/MainHeader';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import {
  isAuthenticated,
  criaCookieToken,
  getDadosUsuario,
  getToken,
  getTokenRefresh,
  getConfigAmbiente
} from '../../shared';

export default ({ rota = false, modulo = false }) => {
  const [fingerPrint, setFingerPrint] = useState(false);
  const [tokenUser, setTokenUser] = useState(false);
  const [tokenRefreshUser, setTokenRefreshUser] = useState(false);
  const [md5User, setMd5User] = useState(false);
  const dadosUser = getDadosUsuario();
  const configAmbiente = getConfigAmbiente();
  const dominio =
    configAmbiente && configAmbiente.sistema && configAmbiente.sistema.dominio
      ? configAmbiente.sistema.dominio
      : 'one7.in';

  useEffect(() => {
    if (isAuthenticated()) {
      getCompleteBrowserInfo();
      buscaTokenUsuario();
    }
  }, []);

  const getCompleteBrowserInfo = async () => {
    const browserInfo = getBrowserInfo();
    const timeZoneInfo = getTimeZoneInfo();

    const completeInfo = {
      browserInfo,
      timeZoneInfo
    };

    const orderedCompleteInfo = Object.keys(completeInfo)
      .sort()
      .reduce((obj, key) => {
        obj[key] = completeInfo[key];
        return obj;
      }, {});

    // Converter o objeto para uma string
    const jsonString = JSON.stringify(orderedCompleteInfo);
    setFingerPrint(jsonString);
    return jsonString;
  };

  const getBrowserInfo = () => {
    const browserInfo = {
      userAgent: navigator.userAgent, // Informações completas do navegador
      language: navigator.language, // Línguagem do navegador
      platform: navigator.platform, // Sistema operacional (Windows, Mac, etc.)
      cookieEnabled: navigator.cookieEnabled, // Se os cookies estão habilitados
      online: navigator.onLine // Se o navegador está online
    };
    return browserInfo;
  };

  const getTimeZoneInfo = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    return { timeZone, locale };
  };

  const buscaTokenUsuario = () => {
    let token = getToken();
    let tokenRefresh = getTokenRefresh();
    setTokenUser(token);
    setTokenRefreshUser(tokenRefresh);
  };

  useEffect(() => {
    if (fingerPrint) {
      let codUsuario = dadosUser && dadosUser.codusuario ? dadosUser.codusuario : null;
      let TempMd5 = MD5(codUsuario + fingerPrint).toString();
      setMd5User(TempMd5);
    }
  }, [fingerPrint]);

  useEffect(() => {
    if (md5User && tokenUser && tokenRefreshUser) {
      criaCookieToken(md5User);
      var rotaRedirect = modulo ? modulo + '|' : '';
      rotaRedirect += rota ? rota : 'dashboard';
      var urlPlataformaCRM = process.env.REACT_APP_LINK_PLATAFORMA_CRM;
      urlPlataformaCRM = urlPlataformaCRM.replace('TENANT', dominio);

      window.location.href = `${urlPlataformaCRM}/plataformas-autenticacao/${rotaRedirect}/${tokenRefreshUser}/${tokenUser}`;
    }
  }, [md5User, tokenUser, tokenRefreshUser]);

  return (
    <>
      <MainHeader />
      <main className="main-without-menu font-family-ibm-plex">
        <LoaderMain />
      </main>
    </>
  );
};
