import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';
import { Typography, CardContent, Grid } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import ButtonMain from '../../shared/ButtonMain';
import { getDadosUsuario, getConfigAmbiente } from '../../../../shared/services/User';

export default () => {
  const [emailUsuario, setEmailUsuario] = useState('');
  const [novoCliente, setNovoCliente] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const configAmbiente = getConfigAmbiente();

  const emailContao =
    (configAmbiente && configAmbiente.sistema && configAmbiente.sistema.email_suporte
      ? configAmbiente.sistema.email_suporte
      : process.env.REACT_APP_EMAIL_SUPORTE) +
    '@' +
    (configAmbiente && configAmbiente.sistema && configAmbiente.sistema.dominio_padrao_email_suporte
      ? configAmbiente.sistema.dominio_padrao_email_suporte
      : process.env.REACT_APP_MAIL_DOMINIO_PADRAO_SUPORTE);

  useEffect(() => {
    var usuario = getDadosUsuario();
    setEmailUsuario(usuario.email_escondido || '');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const acessarHome = () => {
    setNovoCliente({
      clienteEspecial: false,
      clienteOperaConnect: false,
      cnpj: 'todos',
      codCliente: 'todos',
      dadosBrokerCliente: null,
      id: 'todos',
      nomeFantasia: 'TODAS AS EMPRESAS',
      operaTrustee: 'N',
      razaoSocial: 'TODAS AS EMPRESAS'
    });
  };

  const acessaCadastroEmpresa = () => {
    history.push('/entrada-cnpj-onboarding');
  };

  return (
    <CardContent sx={{ margin: '0 0 0 -10px;' }}>
      {novoCliente && (
        <Redirect
          to={{
            pathname: '/authentication/change-client',
            state: { cliente: novoCliente, location }
          }}
        />
      )}
      <Grid container sx={{ p: '20px 0 130px 0' }}>
        <Grid item xl={3} lg={3} md={3} sm={2} xs={12} />
        <Grid item xl={6} lg={6} md={6} sm={8} xs={12} sx={{ p: { xl: '0 40px', lg: '0 40px' } }}>
          <div className="texto-centro">
            <AccessTime className="font-size-45 texto-info" sx={{ color: '#FEB700' }} />
          </div>

          <Typography variant="h5" className="mt-3 texto-cor-principal texto-negrito texto-centro">
            Estamos analisando os dados da empresa
          </Typography>

          <Typography variant="subtitle1" className="mt-3 texto-cor-cinza-escuro pad-0 texto-centro">
            <span>
              Recebemos seus dados e eles estão em análise. Em breve, você receberá uma resposta no
              e-mail
            </span>
            <span> </span>
            <span>
              <b>{emailUsuario}</b>
            </span>
            <br />
            Não se preocupe, estamos trabalhando para atender sua solicitação o mais rápido possível.
            <br />
            Em caso de dúvidas fale com a gente via: chat, pelo telefone ou mande um e-mail para
            <span>
              <a href={`mailto:${emailContao}`}>{emailContao}</a>
            </span>
          </Typography>
        </Grid>
        <Grid container className="mt-3">
          <Grid item xl={3} lg={3} md={2} sm={1} xs={12} />
          <Grid item xl={6} lg={6} md={8} sm={10} xs={12}>
            <Grid container className="mt-3" spacing={3}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 2 }}
              >
                <center>
                  <ButtonMain tipoBotao="azul-escuro" onClick={acessarHome}>
                    Acessar Home
                  </ButtonMain>
                </center>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
              >
                <center>
                  <ButtonMain tipoBotao="principal" onClick={acessaCadastroEmpresa}>
                    Cadastrar outra empresa
                  </ButtonMain>
                </center>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};
