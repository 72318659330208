import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Container, Grid, LinearProgress, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import * as CPF from '@fnando/cpf';
import moment from 'moment';

import { useHistory, useParams } from 'react-router-dom';
import { Visibility } from '@mui/icons-material';
import InputMain from '../../shared/InputMain/InputMain';
import ButtonMain from '../../shared/ButtonMain';
import MainOnlyHeader from '../../../Main/MainOnlyHeader';
import EyeClose from '../../../../assets/fonts/eye-close.svg';
import CheckBoxMain from '../../shared/InputMain/CheckBoxMain';
import AlertErroMain from '../../shared/AlertErroMain';
import strip from '../../../../utils/strip';
import { validaForcaSenha } from '../../shared/Utils';
import { tiposOnboarding } from '../../../../shared/Enums';
import {
  postCadastroLogin,
  validarTokenBroker,
  validarTokenPlataforma,
  validarTokenUsuario
} from '../../../../api/onboarding';
import LoaderMain from '../../shared/LoaderMain';
import {
  setEmailValidaToken,
  setTelaRequisitoraToken,
  getConfigAmbiente
} from '../../../../shared/services/User';

export default () => {
  const {
    tipo_cadastro,
    token_usuario_insert,
    token_plataforma,
    token_unidade_negocio,
    token_broker,
    tipo_onboarding
  } = useParams();
  const [isPending, setPending] = useState(true);
  const [erroSenha, setErroSenha] = useState('');
  const [emailPrincipal, setEmailPrincipal] = useState('');
  const [emailConfirmacao, setEmailConfirmacao] = useState('');
  const [emailIguaisValidos, setEmailIguaisValidos] = useState('');
  const [progress, setProgress] = useState(0);
  const [senhaIguais, setSenhaIguais] = useState(false);
  const [valuesPassword, setValuesPassword] = useState({
    password: '',
    passwordConfirmation: '',
    showPassword: false,
    showPasswordConfirmation: false
  });
  const [textoForcaSenha, setTextoForcaSenha] = useState({
    msgForcaSenha: '',
    corForcaSenha: '',
    corProgress: ''
  });
  const [mostraErro, setMostraErro] = useState(false);
  const [brokerCadastro, setBrokerCadastro] = useState(false);
  const [plataformaCadastro, setPlataformaCadastro] = useState(false);
  const [paramsGerais, setParamsGerais] = useState({});
  const history = useHistory();

  const configAmbiente = getConfigAmbiente();
  const link_termos_de_uso =
    configAmbiente && configAmbiente.documentos && configAmbiente.documentos.termos_e_condicoes_de_uso
      ? configAmbiente.documentos.termos_e_condicoes_de_uso
      : null;
  const link_aviso_de_privacidade =
    configAmbiente && configAmbiente.documentos && configAmbiente.documentos.aviso_de_privacidade
      ? configAmbiente.documentos.aviso_de_privacidade
      : null;

  const forcaSenhaMinimaAceita = 50;

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    var tipoCadastro =
      tipo_cadastro === 'brk' ? 'broker' : tipo_cadastro === 'cli' ? 'cliente' : 'invalido';
    var codUsuarioMD5 =
      token_usuario_insert && token_usuario_insert !== '0' ? token_usuario_insert : 'invalido';
    var tipoOnboarding =
      tipo_onboarding && typeof tiposOnboarding[tipo_onboarding] !== 'undefined'
        ? tipo_onboarding
        : false;
    let tokenPlataforma = token_plataforma && token_plataforma !== '0' ? token_plataforma : 'invalido';

    // OBRIGATORIO OS TOKENS DE: TIPO CADASTRO, USUÁRIO E PLATAFORMA
    if (
      tipoCadastro !== 'invalido' &&
      codUsuarioMD5 !== 'invalido' &&
      (tokenPlataforma !== 'invalido' || tipoOnboarding !== false)
    ) {
      validarTokenUsuario({ token_usuario: codUsuarioMD5 })
        .then(retorno => {
          if (retorno.data && retorno.data.status) {
            if (tokenPlataforma !== 'invalido') {
              buscarValidarTokensPlataformaBroker(tipoCadastro, retorno.data);
            } else if (tipo_onboarding !== false) {
              setParamsGerais({
                tipoCadastro,
                tipoOnboarding: tipoOnboarding,
                adm: retorno.data.status === 'valido-adm'
              });
              setPlataformaCadastro({});
              setBrokerCadastro({});
              setPending(false);
            }
          } else {
            setBrokerCadastro('link_invalido');
            setPlataformaCadastro('link_invalido');
            setPending(false);
          }
        })
        .catch(err => {
          setBrokerCadastro('link_invalido');
          setPlataformaCadastro('link_invalido');
          if (err.response.status === 406 && err.response.data && err.response.data.mensagem) {
            setMostraErro(err.response.data.mensagem);
          } else {
            setMostraErro(err.response);
          }
          setPending(false);
        });
    } else {
      setBrokerCadastro('link_invalido');
      setPlataformaCadastro('link_invalido');
      setPending(false);
    }
  }, []);

  const buscarValidarTokensPlataformaBroker = (tipoCadastro, retornoUser) => {
    let tokenUnidadeNegocio =
      token_unidade_negocio && token_unidade_negocio !== '0' ? token_unidade_negocio : false;
    validarTokenPlataforma({
      token_plataforma: token_plataforma,
      token_unidade_negocio: tokenUnidadeNegocio
    })
      .then(retorno => {
        if (retorno.data && retorno.data.plataforma) {
          setParamsGerais({
            tipoCadastro,
            tipoOnboarding: retorno.data.plataforma.tipoOnboarding,
            adm: retornoUser.status === 'valido-adm'
          });

          if (tipoCadastro === 'broker') {
            setPlataformaCadastro({
              nomePlataforma: retorno.data.plataforma.nomePlataforma,
              iniciais_plataforma: retorno.data.plataforma.iniciais_plataforma,
              codUnidadeNegocio: retorno.data.unidadeNegocio.codUnidadeNegocio
                ? retorno.data.unidadeNegocio.codUnidadeNegocio
                : 0,
              nomeUnidadeNegocio: retorno.data.unidadeNegocio.nomeUnidadeNegocio
                ? retorno.data.unidadeNegocio.nomeUnidadeNegocio
                : 0,
              tipoOnboarding: retorno.data.plataforma.tipoOnboarding
            });
            if (
              tokenUnidadeNegocio &&
              typeof retorno.data.unidadeNegocio.codUnidadeNegocio === 'undefined'
            ) {
              setPlataformaCadastro('link_invalido');
            }
          }
        } else {
          setPlataformaCadastro('link_invalido');
        }
      })
      .catch(err => {
        setBrokerCadastro('link_invalido');
        setPlataformaCadastro('link_invalido');
        if (err.response.status === 406 && err.response.data && err.response.data.mensagem) {
          setMostraErro(err.response.data.mensagem);
        } else {
          setMostraErro(err.response);
        }
      })
      .finally(() => {
        setPending(false);
      });
    if (tipoCadastro === 'cliente' && token_broker && token_broker !== '0') {
      validarTokenBroker({ token_plataforma, token_broker })
        .then(retorno => {
          if (retorno.data && retorno.data.NOMEBROKER) {
            setBrokerCadastro(retorno.data);
          } else {
            setBrokerCadastro('link_invalido');
          }
        })
        .catch(err => {
          setBrokerCadastro('link_invalido');
          setPlataformaCadastro('link_invalido');
          if (err.response.status === 406 && err.response.data && err.response.data.mensagem) {
            setMostraErro(err.response.data.mensagem);
          } else {
            setMostraErro(err.response);
          }
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  // JORNADA DIGITAL ESTA INATIVA POR ENQUANTO [RETIRAR ESTE useEffect QUANDO FOR REATIVADA]
  useEffect(() => {
    if (
      paramsGerais.tipoOnboarding &&
      paramsGerais.tipoOnboarding === 'digital' &&
      paramsGerais.adm === false
    ) {
      setBrokerCadastro('link_invalido');
      setPlataformaCadastro('link_invalido');
      // eslint-disable-next-line no-console
      console.log('TIPO ONBOARDING DIGITAL');
    }
  }, [paramsGerais]);

  const handleFormSubmit = (values, form) => {
    let params = {
      ...values,
      nome: values.nome.toUpperCase(),
      telefone: strip(values.telefone),
      documento: strip(values.documento),
      politica: values.privacidade ? 'S' : 'N',
      termos_uso: values.privacidade ? 'S' : 'N',
      data_nascimento: moment(values.data_nascimento, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
    };

    if (verificaValidaSenha(params.senha, values) < forcaSenhaMinimaAceita) {
      form.setSubmitting(false);
      return false;
    }

    if (brokerCadastro && brokerCadastro.NOMEBROKER) {
      params.token_broker = token_broker;
    }

    if (plataformaCadastro && plataformaCadastro.codUnidadeNegocio) {
      params.codUnidadeNegocio = plataformaCadastro.codUnidadeNegocio;
    }

    params.token_plataforma = token_plataforma && token_plataforma !== '0' ? token_plataforma : false;
    params.tipo_onboarding = paramsGerais.tipoOnboarding;
    params.tipo_cadastro = paramsGerais.tipoCadastro;
    params.token_usuario = token_usuario_insert;
    setPending(true);
    postCadastroLogin(params)
      .then(retorno => {
        if (retorno.status === 201) {
          setEmailValidaToken(values.email);
          setTelaRequisitoraToken('cadastro');
          history.push('/confirmacao');
        } else {
          setMostraErro(true);
        }
      })
      .catch(err => {
        if (err.response.status === 406 && err.response.data && err.response.data.mensagem) {
          setMostraErro(err.response.data.mensagem);
        } else {
          setMostraErro(err.response);
        }
      })
      .finally(() => {
        setPending(false);
        form.setSubmitting(false);
        return false;
      });
  };

  const handleOnChangeEmail = event => {
    setEmailPrincipal(event.target.value);
    validaEmails(event.target.value, emailConfirmacao);
  };

  const handleOnChangeEmailConfirmation = event => {
    setEmailConfirmacao(event.target.value);
    validaEmails(event.target.value, emailPrincipal);
  };

  const validaEmails = (emailPrimario, emailSecundario) => {
    if (emailPrimario !== '' && emailSecundario !== '') {
      let result = emailPrimario === emailSecundario ? '' : 'Os e-mails informados devem ser iguais';
      setEmailIguaisValidos(result);
    } else {
      setEmailIguaisValidos('');
    }
  };

  const handleChangePassword = (prop, formValues) => event => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });

    if (prop === 'password') {
      verificaValidaSenha(event.target.value, formValues);
      validaSenhasIguais(valuesPassword.passwordConfirmation, event.target.value);
    } else {
      validaSenhasIguais(event.target.value, valuesPassword.password);
    }
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword
    });
  };

  const handleClickShowPasswordConfirmation = () => {
    setValuesPassword({
      ...valuesPassword,
      showPasswordConfirmation: !valuesPassword.showPasswordConfirmation
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirmation = event => {
    event.preventDefault();
  };

  const verificaValidaSenha = (password, formValues) => {
    setErroSenha('');
    let forcaSenha = validaForcaSenha(password);
    let textoSenha = '';
    let corSenha = '';
    let corBarra = '';

    if (forcaSenha === 100) {
      textoSenha = 'Forte';
      corSenha = 'texto-success texto-semi-bold';
      corBarra = 'success';
    } else if (forcaSenha === 50) {
      textoSenha = 'Mediana';
      corBarra = 'warning';
      corSenha = 'texto-warning texto-semi-bold';
    } else if (forcaSenha > 0 && forcaSenha < 50) {
      textoSenha = 'Fraca';
      corBarra = 'error';
      corSenha = 'texto-danger texto-semi-bold';
    }

    if (validaSenhaDemaisCampos(password, formValues) === false) {
      textoSenha = 'Fraca';
      corBarra = 'error';
      corSenha = 'texto-danger texto-semi-bold';
      forcaSenha = 0;
    }

    setTextoForcaSenha({
      msgForcaSenha: textoSenha,
      corForcaSenha: corSenha,
      corProgress: corBarra
    });

    setProgress(() => {
      return forcaSenha;
    });
    return forcaSenha;
  };

  const validaSenhaDemaisCampos = (senha, formValues) => {
    if (senha === '') return false;
    if (senha === strip(formValues.documento) || senha === formValues.documento) {
      setErroSenha(', pois sua senha não pode ser igual ao seu CPF.');
      return false;
    } else if (senha === formValues.email) {
      setErroSenha(', pois sua senha não pode ser igual ao seu E-mail');
      return false;
    } else if (
      senha === strip(formValues.telefone) ||
      senha.replace('-', '') === formValues.telefone.replace('-', '')
    ) {
      setErroSenha(', pois sua senha não pode ser igual ao seu Telefone');
      return false;
    } else if (senha === strip(formValues.data_nascimento) || senha === formValues.data_nascimento) {
      setErroSenha(', pois sua senha não pode ser igual a sua Data de Nascimento.');
      return false;
    }
    return true;
  };

  const validaSenhasIguais = (passwor_confimacao, password) => {
    let condicao = passwor_confimacao !== '' && passwor_confimacao !== password ? true : false;

    setSenhaIguais(() => {
      return condicao;
    });
  };

  return (
    <MainOnlyHeader
      brokerCadastro={brokerCadastro}
      plataformaCadastro={plataformaCadastro}
      tela="cadastro_usuario"
    >
      <Container className="font-family-ibm-plex" fixed>
        <Card variant="outlined" className="box-shadow-natural-5 border-radius-8">
          <CardContent
            sx={
              isPending ? { position: 'relative', p: '25px 0 0 0', height: '50vh' } : { p: '25px 0 0 0' }
            }
          >
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="logo" percentTop="35%" />}
            <Grid container sx={isPending ? { display: 'none' } : {}}>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={3} />
              <Grid item xs={10} sm={10} md={8} lg={8} xl={6} sx={{ p: '40px 0' }}>
                {(plataformaCadastro || brokerCadastro) &&
                plataformaCadastro !== 'link_invalido' &&
                brokerCadastro !== 'link_invalido' ? (
                  <>
                    <Typography variant="h5" className="texto-cor-principal texto-negrito texto-centro">
                      Vamos começar
                    </Typography>
                    {/* <Typography variant="subtitle1" className="texto-cor-cinza-escuro pad-10 texto-centro">
                          Preencha o formulário e solicite sua análise de crédito. Sua empresa pode usar recursos
                          financeiros com estratégia, segurança e transparência.
                        </Typography> */}

                    <Formik
                      initialValues={{
                        nome: '',
                        documento: '',
                        data_nascimento: '',
                        email: '',
                        telefone: ''
                      }}
                      validateOnChange={false}
                      validateOnBlur={false}
                      validationSchema={Yup.object().shape({
                        nome: Yup.string()
                          .required('Informe o nome')
                          .test({
                            message: 'Nome inválido',
                            test: value => {
                              if (value && value.toString().replace(/\s/g, '') === '') {
                                return false;
                              } else if (value && value.length > 0) {
                                value = value.trim();
                                return value.includes(' ');
                              }
                              return true;
                            }
                          }),
                        senha: Yup.string()
                          .required('Informe a senha')
                          .nullable()
                          .test({
                            exclusive: true,
                            message: 'Senha muito fraca',
                            name: 'outlined-adornment-password',
                            test: () => {
                              let forcaSenha = validaForcaSenha(valuesPassword.password);
                              if (forcaSenha >= forcaSenhaMinimaAceita) {
                                return true;
                              }
                              return false;
                            }
                          }),
                        password_confirmacao: Yup.string()
                          .required('Informe a senha de confirmação')
                          .nullable()
                          .test({
                            exclusive: true,
                            message: 'As senhas precisam ser iguais',
                            name: 'outlined-adornment-password',
                            test: () => {
                              if (!senhaIguais) {
                                return true;
                              }
                              return true;
                            }
                          }),
                        documento: Yup.string()
                          .required('Informe o CPF')
                          .nullable()
                          .test({
                            exclusive: true,
                            message: params => {
                              const strippedValue = strip(params.value);
                              if (strippedValue.length === 11 && CPF.isValid(strippedValue)) {
                                return 'CPF válido';
                              }
                              return 'CPF inválido';
                            },
                            name: 'documento',
                            test: value => CPF.isValid(value)
                          }),
                        data_nascimento: Yup.string()
                          .required('Informe o nascimento')
                          .nullable()
                          .test({
                            exclusive: true,
                            message: () => {
                              return 'Data inválida';
                            },
                            name: 'data_nascimento',
                            test: data => {
                              if (data !== undefined && data !== '' && data.length >= 10) {
                                data = data.substr(0, 10);
                                let dia = data.split('/')[0];
                                let mes = data.split('/')[1];
                                let ano = data.split('/')[2];
                                let MyData = new Date(ano, mes - 1, dia);

                                if (
                                  MyData.getMonth() + 1 !== parseInt(mes) ||
                                  MyData.getDate() !== parseInt(dia) ||
                                  MyData.getFullYear() !== parseInt(ano)
                                ) {
                                  return false;
                                }
                                if (MyData.getFullYear() < 1900) {
                                  return false;
                                }
                                return true;
                              }
                              return false;
                            }
                          }),
                        telefone: Yup.string()
                          .required('Informe o celular')
                          .test({
                            message: 'Celular inválido',
                            test: value => {
                              if (value !== '' && value !== null && value !== undefined) {
                                let tel = strip(value);
                                let ddd = parseInt(tel.substring(0, 2), 10);
                                let telWithoutDDD = tel.substring(2, tel.length);

                                if (ddd <= 10 || ddd >= 100) {
                                  return false;
                                }
                                return telWithoutDDD.length === 9 &&
                                  [7, 8, 9].includes(parseInt(telWithoutDDD.charAt(0), 10))
                                  ? telWithoutDDD
                                  : false;
                              }
                              return true;
                            }
                          }),
                        email: Yup.string()
                          .required('Informe o e-mail')
                          .nullable()
                          .test({
                            exclusive: true,
                            message: () => {
                              return 'E-mail inválido';
                            },
                            name: 'email',
                            test: value => {
                              if (value !== undefined && value !== '' && value.length > 0) {
                                let result = /\S+@\S+\.\S+/;
                                return result.test(value);
                              }
                              return false;
                            }
                          }),
                        email_confirmacao: Yup.string()
                          .required('Informe o e-mail')
                          .nullable()
                          .test({
                            exclusive: true,
                            message: () => {
                              return 'Os e-mails informados devem ser iguais';
                            },
                            name: 'email_confirmacao',
                            test: value => {
                              if (value !== emailPrincipal) {
                                return false;
                              }
                              return true;
                            }
                          }),
                        privacidade: Yup.bool()
                          .nullable()
                          .test('termos_uso', 'É necessario aceitar o termo de privacidade', value => {
                            if (
                              paramsGerais.tipoOnboarding !== 'connect' &&
                              paramsGerais.tipoOnboarding !== 'indicantes'
                            ) {
                              return value;
                            } else return true;
                          }),
                        termos_uso: Yup.bool()
                          .nullable()
                          .test('termos_uso', 'É necessario aceitar o termo de uso', value => {
                            if (
                              paramsGerais.tipoOnboarding !== 'connect' &&
                              paramsGerais.tipoOnboarding !== 'indicantes'
                            ) {
                              return value;
                            } else return true;
                          })
                      })}
                      onSubmit={handleFormSubmit}
                      render={({ values, isSubmitting }) => {
                        return (
                          <Form className="mt-3 texto-esquerda" autoComplete="off">
                            <ConnectedFocusError />
                            <Field
                              type="text"
                              name="nome"
                              titulo="Nome"
                              id="nome"
                              size="small"
                              className="w-100"
                              uppercase={true}
                              component={InputMain}
                              autoComplete="nope"
                              disabled={isPending || isSubmitting}
                            />

                            <Field
                              type="text"
                              name="documento"
                              titulo="CPF"
                              id="documento"
                              size="small"
                              className="w-100"
                              uppercase={true}
                              component={InputMain}
                              autoComplete="off"
                              tipoMascara="CPFMask"
                              disabled={isPending || isSubmitting}
                            />

                            <Field
                              type="text"
                              name="data_nascimento"
                              titulo="Data de nascimento"
                              id="data_nascimento"
                              size="small"
                              className="w-100"
                              uppercase={true}
                              component={InputMain}
                              autoComplete="off"
                              tipoMascara="dateMask"
                              inputProps={{ maxLength: 10 }}
                              disabled={isPending || isSubmitting}
                            />

                            <Field
                              type="text"
                              name="telefone"
                              titulo="Celular"
                              id="telefone"
                              size="small"
                              className="w-100"
                              uppercase={true}
                              component={InputMain}
                              autoComplete="none"
                              tipoMascara="phoneMask"
                              inputProps={{ maxLength: 15 }}
                              disabled={isPending || isSubmitting}
                            />

                            <Field
                              type="email"
                              name="email"
                              titulo="E-mail"
                              id="email"
                              size="small"
                              component={InputMain}
                              onChangeInput={handleOnChangeEmail}
                              className="w-100"
                              uppercase={false}
                              autoComplete="off"
                              disabled={isPending || isSubmitting}
                            />

                            <Field
                              type="email"
                              name="email_confirmacao"
                              titulo="Confirmar e-mail"
                              id="email_confirmacao"
                              size="small"
                              className="w-100"
                              uppercase={false}
                              component={InputMain}
                              onChangeInput={handleOnChangeEmailConfirmation}
                              erro={emailIguaisValidos}
                              autoComplete="none"
                              disabled={isPending || isSubmitting}
                            />

                            <Typography
                              variant="subtitle1"
                              className="mt-3 texto-cor-principal texto-negrito"
                            >
                              Cadastro de senha
                            </Typography>

                            <Typography variant="subtitle1" className="texto-cor-cinza-escuro">
                              A senha precisa ter pelo menos 8 caracteres, sendo 1 letra maiúscula, 1
                              letra minúscula e 1 número. Não pode ser igual ao CPF, E-Mail, Telefone ou
                              Data de Nascimento
                            </Typography>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Field
                                name="senha"
                                id="password"
                                titulo="Senha"
                                component={InputMain}
                                tipoComponente="outlineInput"
                                type={valuesPassword.showPassword ? 'text' : 'password'}
                                value={valuesPassword.password}
                                onChangeInput={handleChangePassword('password', values)}
                                size="small"
                                autoComplete="new-password"
                                disabled={isPending || isSubmitting}
                                endAdornment={
                                  // eslint-disable-next-line react/jsx-wrap-multilines
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {valuesPassword.showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <img src={EyeClose} alt="icone olho fechado" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Typography variant="subtitle1" className={textoForcaSenha.corForcaSenha}>
                                {textoForcaSenha.msgForcaSenha ? textoForcaSenha.msgForcaSenha : ''}
                                {erroSenha}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              {textoForcaSenha.msgForcaSenha && textoForcaSenha.msgForcaSenha !== '' && (
                                <Box sx={{ width: '100%' }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    color={textoForcaSenha.corProgress}
                                  />
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Field
                                name="password_confirmacao"
                                id="password_confirmacao"
                                titulo="Confirmar senha"
                                component={InputMain}
                                tipoComponente="outlineInput"
                                type={valuesPassword.showPasswordConfirmation ? 'text' : 'password'}
                                value={valuesPassword.passwordConfirmation}
                                onChangeInput={handleChangePassword('passwordConfirmation', values)}
                                size="small"
                                error={senhaIguais}
                                autoComplete="new-password"
                                inputClassName="w-100"
                                disabled={isPending || isSubmitting}
                                onPaste={e => {
                                  e.preventDefault();
                                  return false;
                                }}
                                endAdornment={
                                  // eslint-disable-next-line react/jsx-wrap-multilines
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPasswordConfirmation}
                                      onMouseDown={handleMouseDownPasswordConfirmation}
                                      edge="end"
                                    >
                                      {valuesPassword.showPasswordConfirmation ? (
                                        <Visibility />
                                      ) : (
                                        <img src={EyeClose} alt="icone olho fechado" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />

                              <Typography variant="subtitle1" className="texto-danger texto-semi-bold">
                                {senhaIguais ? 'As senhas devem ser iguais' : ''}
                              </Typography>
                            </Grid>
                            {paramsGerais.tipoOnboarding !== 'connect' &&
                              paramsGerais.tipoOnboarding !== 'indicantes' && (
                                <>
                                  <Field
                                    type="checkbox"
                                    name="termos_uso"
                                    id="termos_uso"
                                    value="S"
                                    classNameLabel="label-link-termos-uso"
                                    className="mt-3"
                                    component={CheckBoxMain}
                                    corCheckBox="checkbox-cor-principal"
                                    disabled={isPending || isSubmitting}
                                    controlLabelCheckbox={
                                      // eslint-disable-next-line react/jsx-wrap-multilines
                                      <div className="mt-3">
                                        <>Li e aceito os </>
                                        <a href={link_termos_de_uso} target="_blank" rel="noreferrer">
                                          Termos e condições de uso.
                                        </a>
                                      </div>
                                    }
                                  />

                                  <Field
                                    type="checkbox"
                                    name="privacidade"
                                    id="privacidade"
                                    value="S"
                                    classNameLabel="label-link-privacidade"
                                    component={CheckBoxMain}
                                    corCheckBox="checkbox-cor-principal"
                                    className="link-politica-privacidade"
                                    disabled={isPending || isSubmitting}
                                    controlLabelCheckbox={
                                      // eslint-disable-next-line react/jsx-wrap-multilines
                                      <div>
                                        <>Li e aceito a </>
                                        <a
                                          href={link_aviso_de_privacidade}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Politica de privacidade.
                                        </a>
                                        <> </>
                                        Dou consentimento para que realizem a coleta e o tratamento dos
                                        meus dados pessoais na forma descrita.
                                      </div>
                                    }
                                  />
                                </>
                              )}

                            <ButtonMain
                              type="submit"
                              tipoBotao="principal"
                              className="mt-5 sem-uppercase"
                              disabled={
                                isPending ||
                                isSubmitting ||
                                progress < forcaSenhaMinimaAceita ||
                                senhaIguais ||
                                valuesPassword.passwordConfirmation === ''
                              }
                            >
                              Solicitar acesso
                            </ButtonMain>
                          </Form>
                        );
                      }}
                    />
                  </>
                ) : brokerCadastro === 'link_invalido' || plataformaCadastro === 'link_invalido' ? (
                  <Typography variant="h6" className="texto-cor-principal texto-negrito texto-centro">
                    O link para cadastro é inválido.
                    <br />
                    Favor contatar o seu gerente ou suporte.
                  </Typography>
                ) : (
                  <Typography variant="h6" className="texto-cor-principal texto-negrito texto-centro">
                    É necessário possuir um link para cadastro.
                    <br />
                    Favor contatar o seu gerente ou suporte.
                  </Typography>
                )}
                <AlertErroMain
                  exibeErro={mostraErro}
                  escondeErro={setMostraErro}
                  msgErro={typeof mostraErro === 'string' ? mostraErro : ''}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </MainOnlyHeader>
  );
};
