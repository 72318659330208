import React from 'react';
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from 'reactstrap';

export default () => (
  <Card>
    <CardBody>
      <CardTitle>500</CardTitle>
      <CardSubtitle>Ocorreu um erro</CardSubtitle>
      <CardText>Ocorreu um erro no servidor, favor entrar em contato com o suporte.</CardText>
    </CardBody>
  </Card>
);
