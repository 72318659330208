import React from 'react';
import swal from '@sweetalert/with-react';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  CustomInput
} from 'reactstrap';

export default (handleCheck, checkedAll, handleCheckAll) => {
  const criarCheckBoxBoletos = row => {
    let disabled = true;
    if (row.boleto) {
      disabled = false;
    }

    return (
      <FormGroup>
        <CustomInput
          type="checkbox"
          className="cursorPointer"
          checked={row.meta.checked}
          disabled={disabled}
          onChange={event => handleCheck(event)}
          id={`check-${row.id}`}
          value={row.id}
        />
      </FormGroup>
    );
  };

  const criarButtonBoletos = row => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          color="primary"
          id={`btnDrop-${row.id}`}
          outline
          className={
            typeof row.boleto === 'undefined' ? 'btn-outline-danger btnBoletoSacado' : 'btnBoletoSacado'
          }
          title="Clique para visualizar mais informações."
          onClick={e => {
            e.preventDefault();
            if (typeof row.boleto === 'undefined') {
              swal({
                title: 'Atenção!',
                text: `${row.mensagemTitleBoleto}.\n \n Para mais informações entre em contato conosco.`,
                content: <>{row.mensagemTitleBoleto}</>,
                icon: 'warning',
                className: 'msgBoletoIndisponivel'
              });
            }
          }}
        >
          {row.boleto ? (
            <>
              BOLETO
              <i className="svg-icon right-side far fa-chevron-down" />
            </>
          ) : (
            'BOLETO INDISPONÍVEL'
          )}
        </DropdownToggle>
        {(row.boleto && (
          <>
            <DropdownMenu>
              <DropdownItem
                className="custom-link"
                onClick={e => {
                  e.preventDefault();
                  window.open(row.boleto.original, `Boleto `, 'resizable,scrollbars,status');
                }}
              >
                ORIGINAL
              </DropdownItem>
              {row.boleto && row.boleto.atualizado && (
                <>
                  <DropdownItem divider />
                  <DropdownItem
                    className="custom-link"
                    onClick={e => {
                      e.preventDefault();
                      window.open(row.boleto.atualizado, `Boleto `, 'resizable,scrollbars,status');
                    }}
                  >
                    ATUALIZADO
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </>
        )) || <></>}
      </UncontrolledDropdown>
    );
  };

  const checkBoxAll = (checkedAll, handleCheckAll) => {
    return (
      <FormGroup>
        <CustomInput
          type="checkbox"
          checked={checkedAll.checked}
          disabled={checkedAll.disabled}
          onChange={event => handleCheckAll(event)}
          id="chkAllTitulos"
          value="all"
          title="Marcar Todos"
        />
      </FormGroup>
    );
  };

  const headerCheckBoxAll = () => {
    return checkBoxAll(checkedAll, handleCheckAll);
  };

  const ordenarBoleto = (a, b, order, dataField, rowA, rowB) => {
    a = typeof rowA.boleto !== 'undefined' ? 1 : 0;
    b = typeof rowB.boleto !== 'undefined' ? 1 : 0;
    var ordenacao = order === 'asc' ? a - b : b - a;
    return ordenacao;
  };

  const config = [
    {
      dataField: 'meta.checked',
      text: '',
      headerFormatter: headerCheckBoxAll,
      formatacao: 'checkbox',
      evento: criarCheckBoxBoletos,
      headerStyle: { width: '40px', textAlign: 'center' },
      sort: false
    },
    {
      dataField: 'fundo',
      text: 'FUNDO',
      formatacao: 'texto',
      headerStyle: { width: '125px', textAlign: 'center' }
    },
    {
      dataField: 'notaFiscal',
      text: 'NOTA FISCAL',
      tipoOrdenacao: 'inteiro',
      formatacao: 'textoOuIfem',
      headerStyle: { width: '125px', textAlign: 'center' }
    },
    {
      dataField: 'documento',
      text: 'DOCUMENTO',
      tipoOrdenacao: 'texto',
      tdAlign: 'center',
      headerStyle: { width: '125px', textAlign: 'center' }
    },
    {
      dataField: 'numeroRemessa',
      text: 'Nº BANCÁRIO',
      formatacao: 'texto',
      tdAlign: 'center',
      headerStyle: { width: '125px', textAlign: 'center' }
    },
    {
      dataField: 'dataProrrogacao',
      text: 'VENCIMENTO',
      tipoOrdenacao: 'data',
      formatacao: 'data',
      headerStyle: { width: '125px', textAlign: 'center' }
    },
    {
      dataField: 'originalData.sacado',
      text: 'SACADO',
      formatacao: 'texto',
      headerStyle: { width: '190px', textAlign: 'center' }
    },
    {
      dataField: 'valorTitulo',
      text: 'VALOR',
      formatacao: 'valor',
      tipoOrdenacao: 'float',
      headerStyle: { width: '125px', textAlign: 'center' }
    },
    {
      dataField: 'BTN',
      text: 'BOLETO',
      formatacao: 'button',
      evento: criarButtonBoletos,
      tipoOrdenacao: ordenarBoleto
    }
  ];

  return config;
};
