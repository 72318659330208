import React, { memo, useState, useEffect } from 'react';
import {
  Button,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup
} from 'reactstrap';
import { Form, Formik, FastField, Field } from 'formik';
import qs from 'query-string';
import * as Yup from 'yup';

import moment from 'moment';
import strip from '../../../utils/strip';
import { getRelatoriosTitulos } from '../../../api/relatorios';
import { TIPO_DOCUMENTOS, TIPOS, TIPO_COBRANCAS } from '../constants';

import { moneyFullFormat, transformTableTitulosData } from '../../../utils/format';
import SkeletonLoader from '../../../shared/components/common/SkeletonLoader';
import RelatorioExport from '../RelatorioExport';
import {
  CPFInput,
  DateTimePicker,
  Input,
  Select,
  CurrencyInput,
  If,
  setCodPlataformaEscolhida,
  verificaMostraFiltroPlataforma,
  listarPlataformaEListarGerentes,
  listaFiltradaGerentesPlataforma,
  listaFiltradaUnidadeNegocioPlataforma,
  normalizaListaGerentesPlataforma,
  normalizaListaUnidadesNegociosPlataforma,
  getCodGerenteEscolhido,
  getCodUnidadeEscolhido,
  setCodGerenteEscolhido,
  setCodUnidadeEscolhido,
  setCodVerticalEscolhida,
  getCodPlataformaEscolhida,
  getConfigAmbiente
} from '../../../shared';
import Grid from '../../commom/Grid';
import titulosGrid from './titulosGrid';
import HistoricoAcesso from '../../commom/HistoricoAcesso';

const Titulos = props => {
  const {
    clienteAtual,
    filtroTipoEmpresas,
    filtroTipoOperacoes,
    isPending,
    search,
    collapseMenuRight,
    setCollapseMenuRight
  } = props;
  const [form, setForm] = useState({});
  const [isLocalPending, setIsLocalPending] = useState(false);
  const [message, setMessage] = useState();
  const [relatorios, setRelatorios] = useState({});
  const [checkedAll, setCheckedAll] = useState({ checked: false, disabled: false });
  const [isDropDownExportarOpened, setIsDropDownExportarOpened] = useState(false);
  const [itensCount, setItensCount] = useState(0);
  const queryParsed = qs.parse(search);

  const [vertical, setVertical] = useState(0);
  const [lstVerticais, setLstVerticais] = useState([]);
  const [plataforma, setPlataforma] = useState(0);
  const [lstPlataformas, setLstPlataformas] = useState([]);
  const mostrarFiltroPlataforma = verificaMostraFiltroPlataforma();
  const [lstPlataformasFiltradas, setLstPlataformasFiltradas] = useState([]);
  const [lstFiltradaGerentePlataforma, setLstFiltradaGerentePlataforma] = useState([]);
  const [gerente, setGerente] = useState(null);
  const [lstFiltradaUnidadeNegocio, setLstFiltradaUnidadeNegocio] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState(null);
  const config = getConfigAmbiente();
  const logoImpressao =
    config &&
    config.imagens &&
    config.imagens.LOGO_RELATORIO &&
    config.imagens.LOGO_RELATORIO !== 'image-default'
      ? config.imagens.LOGO_RELATORIO
      : '';

  useEffect(() => {
    if (search && !mostrarFiltroPlataforma) {
      setIsLocalPending(true);
      setRelatorios({});
      setMessage();

      //Converte os query params para um objeto
      getRelatoriosHandler(queryParsed);
    }
    buscaPlataformas();
  }, []);

  const buscaPlataformas = () => {
    if (!mostrarFiltroPlataforma) {
      return;
    }
    setIsLocalPending(true);
    listarPlataformaEListarGerentes({
      setLstPlataformas: setLstPlataformas,
      setPlataforma: setPlataforma,
      setLstPlataformasFiltradas: setLstPlataformasFiltradas,
      setListaFiltradaGerentes: setLstFiltradaGerentePlataforma,
      setGerenteEscolhido: setGerente,
      setListaFiltradaUnidadeNegocio: setLstFiltradaUnidadeNegocio,
      setUnidadeNegocioEscolhido: setUnidadeNegocio,
      funcaoSucesso: funcaoSucessoPlataforma,
      setPending: setIsLocalPending,
      setLstVerticais: setLstVerticais,
      setVertical: setVertical
    });
  };

  const funcaoSucessoPlataforma = (vertical, plat, ger) => {
    if (search && mostrarFiltroPlataforma) {
      setIsLocalPending(true);
      setRelatorios({});
      setMessage();
      queryParsed.cod_vertical = vertical;
      queryParsed.cod_plataforma = plat;
      queryParsed.cod_gerente = ger;
      getRelatoriosHandler(queryParsed);
    }
  };

  const handleSubmit = (values, actions) => {
    setCodVerticalEscolhida(vertical);
    setCodPlataformaEscolhida([plataforma]);
    setCodUnidadeEscolhido(unidadeNegocio);
    setCodGerenteEscolhido(gerente);

    setIsLocalPending(true);
    setRelatorios({});
    setMessage();
    values = {
      ...values,
      cnpj: strip(values.cnpj),
      data_vencimento_inicial: values.data_vencimento_inicial
        ? moment(values.data_vencimento_inicial, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null,
      data_vencimento_final: values.data_vencimento_final
        ? moment(values.data_vencimento_final, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null,
      data_pagamento_inicial: values.data_pagamento_inicial
        ? moment(values.data_pagamento_inicial, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null,
      data_pagamento_final: values.data_pagamento_final
        ? moment(values.data_pagamento_final, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null,
      data_aceite_inicial: values.data_aceite_inicial
        ? moment(values.data_aceite_inicial, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null,
      data_aceite_final: values.data_aceite_final
        ? moment(values.data_aceite_final, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATE)
        : null
    };

    return getRelatoriosHandler(values).finally(() => {
      actions.setSubmitting(false);
      setCollapseMenuRight(true);
    });
  };

  const operaTrustee = () => {
    let operaTrus = clienteAtual && clienteAtual.operaTrustee;
    return operaTrus;
  };

  const handleCheck = event => {
    const { checked, value } = event.target;
    const newRelatorios = [...relatorios.data];
    let intensCount = 0;
    let marcaCheckAll = newRelatorios.length > 0 ? true : false;
    let desmarcaCheckAll = false;

    newRelatorios.forEach(item => {
      if (parseInt(item.id) === parseInt(value)) {
        item.meta.checked = checked;
      }

      if (item.meta.checked) {
        intensCount++;
      } else if (item.boleto) {
        desmarcaCheckAll = true;
        marcaCheckAll = false;
      }
    });

    const valorTotal =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorDevido).reduce((acc, curr) => acc + curr);
    const valorDevido =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorTitulo).reduce((acc, curr) => acc + curr);

    newRelatorios.data = newRelatorios;
    newRelatorios.valorTotal = valorTotal;
    newRelatorios.valorDevido = valorDevido;

    setItensCount(intensCount);
    setRelatorios(newRelatorios);

    if (marcaCheckAll === true) {
      setCheckedAll({ checked: true, disabled: false });
    } else if (desmarcaCheckAll === true) {
      setCheckedAll({ checked: false, disabled: false });
    }
  };

  const handleCheckAll = event => {
    let { checked } = event.target;
    let newRelatorios = [...relatorios.data];

    let intensCount = 0;
    newRelatorios.forEach(item => {
      if (item.boleto) {
        item.meta.checked = checked;
      }
      if (item.meta.checked) {
        intensCount++;
      }
    });

    let valorTotal =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorDevido).reduce((acc, curr) => acc + curr);
    let valorDevido =
      newRelatorios.length &&
      newRelatorios.map(item => item.valorTitulo).reduce((acc, curr) => acc + curr);

    newRelatorios.data = newRelatorios;
    newRelatorios.valorTotal = valorTotal;
    newRelatorios.valorDevido = valorDevido;

    setItensCount(intensCount);
    setRelatorios(newRelatorios);
    setCheckedAll({ checked: checked, disabled: false });
  };

  const getRelatoriosHandler = values => {
    return getRelatoriosTitulos(values)
      .then(res => {
        let { data } = res;
        let valorTotal =
          data.length && data.map(item => item.valorDevido).reduce((acc, curr) => acc + curr);
        let valorDevido =
          data.length && data.map(item => item.valorTitulo).reduce((acc, curr) => acc + curr);

        data.forEach(item => {
          let disabled = true;
          if (item.boleto) {
            disabled = false;
          }

          item.meta = {
            visible: true,
            disabled: disabled,
            checked: false
          };
        });

        let newRelatorios = {
          data,
          valorTotal,
          valorDevido
        };
        setRelatorios(newRelatorios);

        setCheckedAll({ checked: false, disabled: false });

        if (data.length === 0) {
          setMessage('Nenhum item foi encontrado com estes filtros');
        }
      })
      .catch(() => {
        setMessage('Houve um erro na busca. Por favor, tente novamente mais tarde.');
      })
      .finally(() => {
        setIsLocalPending(false);
        setItensCount(0);
      });
  };

  const handleToggle = () => {
    setIsDropDownExportarOpened(!isDropDownExportarOpened);
  };

  const handlerSelectAction = event => {
    const { value } = event.target;
    event.preventDefault();

    const newRelatorios = [...relatorios.data];
    let url = '';

    let boletos = [];
    newRelatorios.forEach(item => {
      //bruno santana - 01/11/2019 - Pega a base da url que deve ser usa a impressão de boletos em lote, por exemplo: 'https://evale.valecred.com.br/evale'
      url = item.urlBaseImpressaoBoleto;
      if (item.meta.checked && item.boleto.emLote != null && item.boleto.emLote.length > 0) {
        boletos.push(item.boleto.emLote);
      }
    });

    if (boletos && boletos.length > 0) {
      url += '/iSeven/boleto_lote.php?tipoLayout=' + value;
      /**
       * - Douglas 04/11/2019
       * ALTERACAO PARA POST DE BOLETO EM LOTE.
       * alterado pois via GET existe um limite de quantidade, POST de um array de tokens não existe esse limite
       */
      var winBoleto = window.open('', `Boleto `, 'resizable,scrollbars,status');
      var boletosHtml = `<form id="form" method="post" action="${url}">`;
      boletos.forEach(token => {
        // Douglas 04/11/2019 - no html quando utliza o mesmo nome no imput seguido de [], na requisição ao invez de enviar vários inputs, é enviado um array dos valores no mesmo input
        boletosHtml += `<input type="hidden" name="token[]" value="${token}" />`;
      });
      boletosHtml += '</form>';
      boletosHtml += "<script>document.getElementById('form').submit();</script>";
      winBoleto.document.write(boletosHtml);
    } else {
      alert('Sem boletos para geraçao');
    }
  };

  useEffect(() => {
    if (form && form.setFieldValue && vertical && lstPlataformas.length > 0) {
      form.setFieldValue('cod_vertical', vertical);

      let plataformas = [...lstPlataformas].filter(
        plataf => parseInt(plataf.codVertical) === parseInt(vertical)
      );
      setLstPlataformasFiltradas(plataformas);

      let platafEscolhida =
        plataformas && plataformas[0] && plataformas[0].value ? plataformas[0].value : null;
      if (
        getCodPlataformaEscolhida() &&
        plataformas &&
        plataformas.filter(e => e.value === getCodPlataformaEscolhida()[0]).length > 0
      ) {
        platafEscolhida = getCodPlataformaEscolhida()[0];
      }
      setPlataforma(platafEscolhida);
    }
  }, [vertical]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_plataforma', Array.isArray(plataforma) ? plataforma[0] : plataforma);

      let lstFiltradaUnidadeNegocioPlataforma = listaFiltradaUnidadeNegocioPlataforma(
        Array.isArray(plataforma) ? plataforma : [plataforma]
      );
      let unidadeNegocioEscolhido = null;
      if (lstFiltradaUnidadeNegocioPlataforma.length > 0) {
        lstFiltradaUnidadeNegocioPlataforma = normalizaListaUnidadesNegociosPlataforma(
          lstFiltradaUnidadeNegocioPlataforma
        );
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
        unidadeNegocioEscolhido =
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma[0] &&
          lstFiltradaUnidadeNegocioPlataforma[0].value
            ? lstFiltradaUnidadeNegocioPlataforma[0].value
            : null;

        if (
          getCodUnidadeEscolhido() &&
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma.filter(e => e.value === getCodUnidadeEscolhido()).length >
            0
        ) {
          unidadeNegocioEscolhido = getCodUnidadeEscolhido();
        }
      } else {
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
      }
      setUnidadeNegocio(unidadeNegocioEscolhido);
    }
  }, [plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_unidade_negocio', unidadeNegocio);

      let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(plataforma, unidadeNegocio);
      lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);
      setLstFiltradaGerentePlataforma(lstFiltradaGerentes);

      let gerenteEscolhido =
        lstFiltradaGerentes && lstFiltradaGerentes[0] && lstFiltradaGerentes[0].value
          ? lstFiltradaGerentes[0].value
          : null;

      if (
        getCodGerenteEscolhido() &&
        lstFiltradaGerentes &&
        lstFiltradaGerentes.filter(e => e.value === getCodGerenteEscolhido()).length > 0
      ) {
        gerenteEscolhido = getCodGerenteEscolhido();
      }
      setGerente(gerenteEscolhido);
    }
  }, [unidadeNegocio, plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_gerente', gerente);
    }
  }, [gerente]);

  return (
    <>
      <Formik
        ref={node => setForm(node)}
        initialValues={{
          tipo: parseInt(queryParsed.tipo || 5), // por padrão => 'Em Aberto'
          data_vencimento_final:
            queryParsed && queryParsed.data_vencimento_final
              ? moment(queryParsed.data_vencimento_final, moment.HTML5_FMT.DATE).format('DD/MM/YYYY')
              : '',
          data_vencimento_inicial:
            queryParsed && queryParsed.data_vencimento_inicial
              ? moment(queryParsed.data_vencimento_inicial, moment.HTML5_FMT.DATE).format('DD/MM/YYYY')
              : '',
          data_aceite_inicial:
            queryParsed && queryParsed.data_aceite_inicial
              ? moment(queryParsed.data_aceite_inicial, moment.HTML5_FMT.DATE).format('DD/MM/YYYY')
              : '',
          data_aceite_final:
            queryParsed && queryParsed.data_aceite_final
              ? moment(queryParsed.data_aceite_final, moment.HTML5_FMT.DATE).format('DD/MM/YYYY')
              : ''
        }}
        validationSchema={Yup.object().shape({
          tipo: Yup.string()
            .nullable()
            .required('Campo obrigatório.')
        })}
        onSubmit={handleSubmit}
        render={({ isSubmitting }) => {
          return (
            <div className="row">
              <div
                className={
                  (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                  ' col-12 content-body content-relatorio '
                }
              >
                <div className="body">
                  <div className="row">
                    <div className="col-lg-10 col-md-8 col-titulo-relatorio">
                      <h1 className="title">Relatório de Títulos</h1>
                      <p className="subTitle">Filtre ao lado para visualizar seus Títulos.</p>
                    </div>
                    <div className="col-lg-2 col-md-4 form-inline d-flex flex-row-reverse bd-highlight export-buttons">
                      {collapseMenuRight && (
                        <button
                          type="button"
                          onClick={() => setCollapseMenuRight(false)}
                          title="Filtros"
                          className="btn btn-outline-secondary btn-show-menu-right float-right"
                        >
                          <i className="fa fa-angle-left" />
                          <span>Filtros</span>
                        </button>
                      )}
                      {relatorios.data && (
                        <Dropdown isOpen={isDropDownExportarOpened} toggle={() => handleToggle()}>
                          <DropdownToggle
                            color="outline-primary"
                            disabled={!relatorios.data.length || isPending || isLocalPending}
                          >
                            Exportar
                            <i className="svg-icon right-side far fa-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => RelatorioExport(relatorios.data, 'csv')}>
                              CSV
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => RelatorioExport(relatorios.data, 'xls', operaTrustee())}
                            >
                              XLS
                            </DropdownItem>
                            <DropdownItem onClick={() => window.print()}>IMPRIMIR</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </div>
                    <div className="col-md-12 d-flex flex-row-reverse bd-highlight">
                      <div
                        className={`form-inline selecao-itens animated ${
                          itensCount ? 'fadeIn' : 'fadeOut'
                        }`}
                      >
                        <span className="selecao-itens-numero m-2 half-rounded-circle">
                          {itensCount}
                        </span>
                        Boletos em Lote
                        <select
                          className="form-control ml-2"
                          disabled={itensCount === 0}
                          onChange={handlerSelectAction}
                          value="nenhuma"
                        >
                          <option value="nenhuma">Selecione</option>
                          <option value="original">Original</option>
                          <option value="atualizado">Atualizado</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="nomeCliente">
                    <Label>Cliente: &nbsp;</Label>
                    {clienteAtual && (clienteAtual.razaoSocial || clienteAtual.nomeFantasia)}
                    <br />
                    <Label>Emitido em: &nbsp;</Label>
                    {moment().format('DD/MM/YYYY') + ' às ' + moment().format('HH:mm:ss')}
                    {relatorios.data && relatorios.data.length
                      ? ` por ${relatorios.data[0].apelidoUsuario}`
                      : ''}
                    <br />
                    <Label>Tipo Filtro: &nbsp;</Label>
                    {relatorios.data && relatorios.data.length
                      ? relatorios.data[0].tipoFiltroSelecionado
                      : ''}
                    {relatorios.data ? ` - ${relatorios.data.length} títulos filtrados` : ''}
                    <img
                      className="logoOne7 float-right"
                      src={logoImpressao}
                      width="150"
                      alt="Empresa logo"
                    />
                  </div>
                  <SkeletonLoader
                    isPending={isPending || isLocalPending}
                    width="100%"
                    height="40px"
                    count={8}
                    widthRandomness={0}
                  >
                    <If test={Object.keys(relatorios).length > 0}>
                      <Grid
                        keyField="id"
                        data={transformTableTitulosData(relatorios.data)}
                        configuracaoColunas={titulosGrid(
                          handleCheck,
                          checkedAll,
                          handleCheckAll,
                          operaTrustee(),
                          clienteAtual
                        )}
                        mensagem={message}
                        classes="table-layout-fixed"
                        classeIdentificadora="titulos"
                      />

                      {relatorios.data && relatorios.data.length > 0 && (
                        <div className="row" style={{ marginTop: 5 }}>
                          <div className="col-md-12 ml-auto mt-12 text-right">
                            <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                              Quantidade
                              <span className="badge badge-relatorio">{relatorios.data.length}</span>
                            </Label>
                            <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                              Valor total
                              <span className="badge badge-relatorio">
                                {moneyFullFormat(relatorios && relatorios.valorTotal)}
                              </span>
                            </Label>
                            <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                              Valor total devido
                              <span className="badge badge-relatorio">
                                {moneyFullFormat(relatorios && relatorios.valorDevido)}
                              </span>
                            </Label>
                            {relatorios.data && operaTrustee() === 'S' && (
                              <Label className="col-md-12 col-lg-6 col-xl-3 align-items-center">
                                Total valor pago
                                <span className="badge badge-relatorio">
                                  {moneyFullFormat(
                                    relatorios &&
                                      relatorios.data.reduce(
                                        (total, valor) => Number(total) + Number(valor.valorPago),
                                        0
                                      )
                                  )}
                                </span>
                              </Label>
                            )}
                          </div>
                        </div>
                      )}
                    </If>
                  </SkeletonLoader>
                </div>
                <div className="footer"> </div>
              </div>
              {!collapseMenuRight && (
                <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                  <Form>
                    <div className="fixed-side">
                      <div
                        className="header cursor-pointer"
                        onClick={() => setCollapseMenuRight(true)}
                        onKeyPress={() => setCollapseMenuRight(true)}
                        role="button"
                        tabIndex={0}
                      >
                        <h4 className="title">
                          Filtros
                          <i className="fa fa-angle-right float-right" />
                        </h4>
                      </div>
                      <div className="body">
                        <SkeletonLoader
                          isPending={isPending}
                          width="100%"
                          height="20px"
                          count={8}
                          widthRandomness={0}
                        >
                          <div className="container p-0">
                            <FormGroup>
                              <Label>Cliente:</Label>
                              <p className="form-control-static">
                                {clienteAtual && (clienteAtual.razaoSocial || clienteAtual.nomeFantasia)}
                              </p>
                            </FormGroup>

                            {mostrarFiltroPlataforma && lstVerticais && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    label="Vertical"
                                    id="cod_vertical"
                                    name="cod_vertical"
                                    className="select-plataforma-relatorios"
                                    options={lstVerticais}
                                    onChange={e => {
                                      setVertical(e.value);
                                    }}
                                    disabled={isSubmitting || lstVerticais.length === 1}
                                    component={Select}
                                    autoComplete="off"
                                    isClearable={false}
                                    placeholder="-- Selecione"
                                  />
                                </div>
                              </div>
                            )}

                            {mostrarFiltroPlataforma && lstPlataformasFiltradas && (
                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    label="Plataforma"
                                    id="cod_plataforma"
                                    name="cod_plataforma"
                                    className="select-plataforma-relatorios"
                                    options={lstPlataformasFiltradas}
                                    onChange={e => {
                                      setPlataforma(e.value);
                                    }}
                                    disabled={isSubmitting || lstPlataformasFiltradas.length === 1}
                                    component={Select}
                                    autoComplete="off"
                                    isClearable={false}
                                    placeholder="-- Selecione"
                                  />
                                </div>
                              </div>
                            )}

                            {mostrarFiltroPlataforma &&
                              lstPlataformasFiltradas &&
                              lstFiltradaUnidadeNegocio &&
                              unidadeNegocio && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Unidade de Negócio"
                                      id="cod_unidade_negocio"
                                      name="cod_unidade_negocio"
                                      className="select-plataforma-relatorios"
                                      options={lstFiltradaUnidadeNegocio}
                                      onChange={e => {
                                        setUnidadeNegocio(e.value);
                                      }}
                                      disabled={isSubmitting || lstFiltradaUnidadeNegocio.length === 1}
                                      component={Select}
                                      autoComplete="off"
                                      isClearable={false}
                                      placeholder="-- Selecione"
                                    />
                                  </div>
                                </div>
                              )}

                            {mostrarFiltroPlataforma &&
                              lstPlataformasFiltradas &&
                              lstFiltradaGerentePlataforma &&
                              gerente && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <Field
                                      label="Gerente"
                                      id="cod_gerente"
                                      name="cod_gerente"
                                      className="select-plataforma-relatorios"
                                      options={lstFiltradaGerentePlataforma}
                                      onChange={e => {
                                        setGerente(e.value);
                                      }}
                                      disabled={
                                        isSubmitting || lstFiltradaGerentePlataforma.length === 1
                                      }
                                      component={Select}
                                      autoComplete="off"
                                      isClearable={false}
                                      placeholder="-- Selecione"
                                    />
                                  </div>
                                </div>
                              )}

                            <div className="row">
                              <div className="col-md-12">
                                <FastField
                                  label="Tipo"
                                  labelClassName="required-label"
                                  id="type"
                                  name="tipo"
                                  options={TIPOS.map(({ code, descricao }) => {
                                    return {
                                      value: code,
                                      label: descricao
                                    };
                                  })}
                                  component={Select}
                                  disabled={isSubmitting}
                                  placeholder="-- Selecione"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <FastField
                                  type="text"
                                  label="Sacado"
                                  name="sacado"
                                  id="Sacado"
                                  disabled={isSubmitting}
                                  component={Input}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  type="text"
                                  label="CPF/CNPJ do Sacado"
                                  name="cnpj"
                                  id="cnpj"
                                  disabled={isSubmitting}
                                  component={CPFInput}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <FastField
                                  label="Tipo de Documento"
                                  id="documentType"
                                  name="tipo_documento"
                                  options={TIPO_DOCUMENTOS.map(({ code, descricao }) => {
                                    return {
                                      value: code,
                                      label: descricao
                                    };
                                  })}
                                  component={Select}
                                  disabled={isSubmitting}
                                  placeholder="-- Selecione"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  label="Tipo de Operação"
                                  id="operationType"
                                  name="tipo_operacao"
                                  options={filtroTipoOperacoes}
                                  component={Select}
                                  disabled={isSubmitting}
                                  placeholder="-- Selecione"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Label>Vencimento entre:</Label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FastField
                                  name="data_vencimento_inicial"
                                  id="vencimento-start"
                                  placeholder="DD/MM/AAAA"
                                  disableWeekend={false}
                                  disabled={isSubmitting}
                                  component={DateTimePicker}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-md-6">
                                <FastField
                                  name="data_vencimento_final"
                                  id="vencimento-end"
                                  placeholder="DD/MM/AAAA"
                                  disableWeekend={false}
                                  disabled={isSubmitting}
                                  component={DateTimePicker}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Label>Pagamento entre:</Label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FastField
                                  name="data_pagamento_inicial"
                                  id="pagamento-start"
                                  placeholder="DD/MM/AAAA"
                                  disableWeekend={false}
                                  disabled={isSubmitting}
                                  component={DateTimePicker}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-md-6">
                                <FastField
                                  name="data_pagamento_final"
                                  id="pagamento-end"
                                  placeholder="DD/MM/AAAA"
                                  disableWeekend={false}
                                  disabled={isSubmitting}
                                  component={DateTimePicker}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Label>Data aceite:</Label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FastField
                                  name="data_aceite_inicial"
                                  id="aceite-start"
                                  placeholder="DD/MM/AAAA"
                                  disableWeekend={false}
                                  disabled={isSubmitting}
                                  component={DateTimePicker}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-md-6">
                                <FastField
                                  name="data_aceite_final"
                                  id="aceite-end"
                                  placeholder="DD/MM/AAAA"
                                  disableWeekend={false}
                                  disabled={isSubmitting}
                                  component={DateTimePicker}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Label>Valor entre:</Label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <FastField
                                  name="valor_inicial"
                                  id="valor-start"
                                  placeholder="R$ 0,00"
                                  disabled={isSubmitting}
                                  component={CurrencyInput}
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-md-6">
                                <FastField
                                  name="valor_final"
                                  id="valor-end"
                                  placeholder="R$ 0,00"
                                  disabled={isSubmitting}
                                  component={CurrencyInput}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <FastField
                                  type="text"
                                  label="Borderôs"
                                  name="bordero"
                                  id="bordero"
                                  placeholder="bordero1, bordero2..."
                                  disabled={isSubmitting}
                                  component={Input}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <FastField
                                  label="Tipo de Cobrança"
                                  id="chargingType"
                                  name="tipo_cobranca"
                                  options={TIPO_COBRANCAS.map(({ code, descricao }) => {
                                    return {
                                      value: code,
                                      label: descricao
                                    };
                                  })}
                                  component={Select}
                                  disabled={isSubmitting}
                                  placeholder="-- Selecione"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <FastField
                                  type="text"
                                  label="Documentos"
                                  name="documento"
                                  id="documentos"
                                  disabled={isSubmitting}
                                  placeholder="documento1, documento2..."
                                  component={Input}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <FastField
                                  type="text"
                                  label="Nº da Nota Fiscal"
                                  name="nota_fiscal"
                                  id="noNotaFiscal"
                                  placeholder=""
                                  disabled={isSubmitting}
                                  component={Input}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  label="Tipo de Empresa"
                                  id="companyType"
                                  name="tipo_empresa"
                                  options={filtroTipoEmpresas}
                                  component={Select}
                                  disabled={isSubmitting}
                                  placeholder="-- Selecione"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </SkeletonLoader>
                      </div>
                      {/*style={{ display: exibeFiltros ? 'block' : 'none' }}*/}
                      <div className="bottom">
                        <Button color="primary" type="submit" block disabled={isSubmitting || isPending}>
                          Filtrar
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          );
        }}
      />
      <HistoricoAcesso codTela={1203} />
    </>
  );
};
export default memo(Titulos);
