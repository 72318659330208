import axios from 'axios';
import {
  getToken,
  getTokenRefresh,
  getClienteAtual,
  setToken,
  setTokenRefresh,
  removeToken,
  removeTokenRefresh,
  getConfigAmbiente
} from '../shared';

const api = axios.create({ baseURL: `${process.env.REACT_APP_API_BASE_URL}` });

api.interceptors.request.use(
  config => {
    const { url } = config;
    const token = getToken();
    const cliente = getClienteAtual();

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    } else if (
      url.toLowerCase().indexOf('/auth/') < 0 &&
      url.toLowerCase().indexOf('/login') < 0 &&
      url.toLowerCase().indexOf('/area-sacado') < 0 &&
      url.toLowerCase().indexOf('/usuarios') < 0
    ) {
      removeToken();
      removeTokenRefresh();
      document.location.reload(true);
      return config;
    }

    if (cliente && url.toLowerCase().indexOf('/login') < 0) {
      config.headers['x-one7-codcliente'] = cliente.id ? cliente.id : 'nao-tem-cliente';
    }

    // SE O REQUEST ESTÁ ENVIANDO PARA O UPLOAD, ENVIA NO HEADER A PASTA DO TENANT
    if (url.toLowerCase().indexOf('/upload/') >= 0) {
      const configAmbiente = getConfigAmbiente();
      config.headers['x-root-dir'] =
        configAmbiente && configAmbiente.sistema && configAmbiente.sistema.pasta_bucket
          ? configAmbiente.sistema.pasta_bucket
          : 'nulo';
    }

    return config;
  },
  error => {
    sessionStorage.clear();
    document.location.reload(true);
    return Promise.reject(error);
  }
);

const refreshAccessToken = () => {
  const form = new URLSearchParams();
  form.append('client_id', process.env.REACT_APP_CLIENT_ID);
  form.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
  form.append('grant_type', process.env.REACT_APP_GRANT_TYPE_REFRESH_TOKEN);
  form.append('refresh_token', getTokenRefresh());
  return api
    .post(`${process.env.REACT_APP_LOGIN}/login`, form)
    .then(ret => {
      const { access_token, refresh_token } = ret.data;
      setToken(access_token);
      setTokenRefresh(refresh_token);
      return access_token;
    })
    .catch(() => {
      sessionStorage.clear();
      document.location.reload(true);
      return Promise.reject();
    });
};

const refreshAndRetryQueue = [];

let isRefreshing = false;

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newAccessToken = await refreshAccessToken();
          error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            api
              .request(config)
              .then(response => resolve(response))
              .catch(err => reject(err));
          });
          refreshAndRetryQueue.length = 0;

          return api(originalRequest);
        } catch (refreshError) {
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }
    return Promise.reject(error);
  }
);

export default api;
