import React, { useEffect, useState } from 'react';
// import { Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';
import CheckBoxMain from '../../../Onboarding/shared/InputMain/CheckBoxMain';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import DocumentoUpload from '../../../Onboarding/shared/DocumentoUpload';
import { getClienteAtual, getConfigAmbiente } from '../../../../shared';
import { incluiPropostaOne7MaisCredito } from '../../../../api/one7MaisCredito';
import documentosGarantias from '../../../Onboarding/shared/Enums/EnumsDocumentosGarantias';
import AlertErroMain from '../../../Onboarding/shared/AlertErroMain';
import { unmaskValue } from '../../../../utils/format';

export default ({ dadosFormulario, setGarantiaFinalizada }) => {
  const [isPendingDocumentos, setPendingDocumentos] = useState(false);
  const [isPendingUpload, setPendingUpload] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [isPending, setPending] = useState(false);
  const clienteAtual = getClienteAtual();
  const cnpjCliente = clienteAtual && clienteAtual.cnpj ? clienteAtual.cnpj : null;
  const dadosIniciaisForm =
    dadosFormulario && Object.keys(dadosFormulario).length > 0 ? dadosFormulario : {};
  const [fingerPrint, setFingerPrint] = useState('');
  const [mostraErro, setMostraErro] = useState(false);

  const configAmbiente = getConfigAmbiente();
  const link_termos_de_uso =
    configAmbiente && configAmbiente.documentos && configAmbiente.documentos.termos_e_condicoes_de_uso
      ? configAmbiente.documentos.termos_e_condicoes_de_uso
      : null;
  const link_aviso_de_privacidade =
    configAmbiente && configAmbiente.documentos && configAmbiente.documentos.aviso_de_privacidade
      ? configAmbiente.documentos.aviso_de_privacidade
      : null;

  const getFingerPrint = async () => {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const resp = await fp.get();
    setFingerPrint(resp);
  };

  useEffect(() => {
    setPendingDocumentos(true);

    getFingerPrint();

    let tipoGarantia =
      dadosIniciaisForm && dadosIniciaisForm.tipo_garantia ? dadosIniciaisForm.tipo_garantia : null;

    let docTemp = documentosGarantias[tipoGarantia].map(doc => {
      delete doc['tamanho_arquivo'];
      delete doc['hash_arquivo'];
      delete doc['link_arquivo'];
      delete doc['nome_arquivo'];
      delete doc['cpfcnpj'];
      return doc;
    });

    setDocumentos(docTemp);

    setTimeout(() => {
      setPendingDocumentos(false);
    }, 250);
  }, []);

  const confirmarDocumentos = (values, form) => {
    var documentosTemp = [...documentos];
    var bloquearSubmit = false;
    documentosTemp = documentosTemp.map(doc => {
      if ((doc.hash_arquivo === undefined || doc.hash_arquivo === '') && doc.obrigacao_doc) {
        bloquearSubmit = true;
        if (doc.nome_arquivo === undefined || doc.nome_arquivo === '') {
          doc.status = 'reprovado';
          doc.titulo_status = 'Pendente';
          doc.subtitulo_status = 'Importe um arquivo para este documento';
        }
      }
      return doc;
    });

    if (bloquearSubmit) {
      setDocumentos(documentosTemp);
      return false;
    }

    let params = formatarPayloadDocumentos(documentos, cnpjCliente, dadosIniciaisForm);

    // AQUI ENVIA TODO O POST COM OS DEMAIS DADOS
    setPending(true);
    incluiPropostaOne7MaisCredito(params)
      .then(() => {
        finalizaJornadaCredito();
      })
      .catch(err => {
        setPending(false);
        form.setSubmitting(false);
        setMostraErro(err.response);
      });
  };

  const finalizaJornadaCredito = () => {
    setTimeout(() => {
      setPending(false);
      setGarantiaFinalizada(true);
    }, 1000);
  };

  const formatarPayloadDocumentos = (dadosDocumentos, cpnjEmpresa, dadosIniciais) => {
    let tempDocs = [];
    dadosDocumentos.map(doc => {
      if (
        typeof doc.hash_arquivo !== 'undefined' &&
        doc.hash_arquivo !== undefined &&
        doc.hash_arquivo !== null
      ) {
        let tempDoc = {
          cpnj_empresa: cpnjEmpresa,
          hash: doc.hash_arquivo,
          cod_documento: doc.id
        };
        tempDocs.push(tempDoc);
      }
      return doc;
    });

    let params = dadosIniciais;
    params.cnpj = cpnjEmpresa;
    params.documentos = tempDocs;
    params.termos_uso = 'S';
    params.politica_privacidade = 'S';
    params.valor_garantia = unmaskValue(dadosIniciais.valor_garantia);
    if (params.tipo_garantia === 'IM') {
      params.valor_imovel = unmaskValue(dadosIniciais.valor_imovel);
    }

    let finger = '';
    if (fingerPrint && fingerPrint.visitorId) {
      finger = {
        id: fingerPrint.visitorId,
        ...fingerPrint.components
      };
      finger = JSON.stringify(finger);
    }
    params.fingerprint = finger;

    return params;
  };

  const handleFormSubmit = (values, form) => {
    let resultValidacaoDoc = confirmarDocumentos(values, form);
    if (resultValidacaoDoc === false) {
      setPending(false);
      form.setSubmitting(false);
      return false;
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        privacidade: Yup.bool()
          .nullable()
          .test('termos_uso', 'É necessario aceitar a politica de privacidade', value => {
            return value;
          }),
        termos_uso: Yup.bool()
          .nullable()
          .test('termos_uso', 'É necessario aceitar os termos de uso', value => {
            return value;
          })
      })}
      onSubmit={handleFormSubmit}
    >
      {({ isSubmitting }) => (
        <Box
          className="box-jornada-documentos"
          sx={isPending ? { position: 'relative', minHeight: '350px' } : {}}
        >
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Form className="texto-esquerda" autoComplete="off">
            <ConnectedFocusError />

            <Box
              sx={
                isPendingDocumentos
                  ? { position: 'relative', marginTop: '20px', minHeight: '150px' }
                  : {}
              }
            >
              {isPendingDocumentos && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
              {documentos.length !== 0 &&
                documentos.map((doc, index) => {
                  var bordaBottom =
                    index + 1 === documentos.length ? 'borda-transparente' : 'borda-cor-cinza-claro';
                  return (
                    <DocumentoUpload
                      key={doc.id}
                      id={doc.id}
                      boxClassesAdicionais={bordaBottom}
                      isPending={isPendingUpload}
                      setPending={setPendingUpload}
                      listaDocs={documentos}
                      setListaDocs={setDocumentos}
                      arquivosAceitos={['pdf', 'imagens', 'word', 'powerPoint', 'excel', 'compactados']}
                      tamanhoMaximoMegaAceito={30}
                      cnpjEmpresa={cnpjCliente}
                    />
                  );
                })}
            </Box>

            <Field
              type="checkbox"
              name="termos_uso"
              id="termos_uso"
              value="S"
              classNameLabel="label-link-termos-uso"
              component={CheckBoxMain}
              corCheckBox="checkbox-cor-complementar"
              disabled={isSubmitting || isPending}
              controlLabelCheckbox={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="texto-cor-principal">
                  <>Li e aceito os </>
                  <a href={link_termos_de_uso} target="_blank" rel="noreferrer">
                    Termos e condições de uso
                  </a>
                  <>.</>
                </div>
              }
            />

            <Field
              type="checkbox"
              name="privacidade"
              id="privacidade"
              value="S"
              classNameLabel="label-link-privacidade"
              component={CheckBoxMain}
              corCheckBox="checkbox-cor-complementar"
              className="link-politica-privacidade"
              disabled={isSubmitting || isPending}
              controlLabelCheckbox={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="texto-cor-principal">
                  <>Li e aceito a </>
                  <a href={link_aviso_de_privacidade} target="_blank" rel="noreferrer">
                    Politica de privacidade
                  </a>
                  . Dou consentimento para que seja realizado a coleta e o tratamento dos meus dados
                  pessoais na forma descrita.
                </div>
              }
            />

            <AlertErroMain
              exibeErro={mostraErro}
              escondeErro={setMostraErro}
              msgErro={typeof mostraErro === 'string' ? mostraErro : ''}
            />

            <ButtonMain
              type="submit"
              tipoBotao="principal"
              className="mt-2 sem-uppercase"
              disabled={isSubmitting || isPending || isPendingUpload || isPendingDocumentos}
            >
              Enviar para análise
            </ButtonMain>
          </Form>
        </Box>
      )}
    </Formik>
  );
};
