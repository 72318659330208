import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import UsuarioLogado from './UsuarioLogado/UsuarioLogado';
import { getConfigAmbiente } from '../../shared/services/User';

export default () => {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mostraFundoEscuro, setMostraFundoEscuro] = React.useState(false);
  const history = useHistory();
  const rotaInterna =
    history.location.pathname !== '/cadastro' && history.location.pathname !== '/authentication/login';

  const config = getConfigAmbiente();
  const logo =
    config && config.imagens && config.imagens.LOGO && config.imagens.LOGO !== 'image-default'
      ? config.imagens.LOGO
      : '';

  return (
    <div className="header-main" style={{ backgroundColor: rotaInterna ? '#ffffff' : 'transparent' }}>
      {mostraFundoEscuro && <div className="div-fundo-escuro" />}
      <div className="img-logo-onboarding">
        {onlySmallScreen ? (
          <div className="img-logo-coll-onboarding" style={{ backgroundImage: `url(${logo})` }} />
        ) : (
          <div className="img-logo" style={{ backgroundImage: `url(${logo})` }} />
        )}
      </div>
      <div className="header-onboarding-usuario-logado">
        <UsuarioLogado setMostraFundoEscuro={setMostraFundoEscuro} />
      </div>
    </div>
  );
};
