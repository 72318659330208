/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, memo } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink, Button, Collapse } from 'reactstrap';
// import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
//import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Home from './icons/Home/Home';
import Borderos from './icons/Borderos/Borderos';
import Dashboard from './icons/Dashboard/Dashboard';
import PlataformaCrmIcon from './icons/PlataformaCrm/PlataformaCrm';
import RelacionamentosCrm from './icons/RelacionamentosCrm/RelacionamentosCrm';
import Instrucoes from './icons/Instrucoes/Instrucoes';
import Relatorios from './icons/Relatorios/Relatorios';
import Retorno from './icons/Retorno/Retorno';
import imageDefault from '../../assets/img/botao-fechar.svg';
import { getClienteAtual, getClientes, getConfigAmbiente } from '../../shared/services/User';

const Sidebar = props => {
  const { location = { pathname: '' }, escopoAcesso = false } = props;
  const [sidebarMini, setSidebarMini] = useState(true);
  // const [sidebarMiniTutorial, setSidebarMiniTutorial] = useState(true);
  const [collapse, setCollapse] = useState(location.pathname.includes('relatorios'));
  // const [collapseTutorial, setCollapseTutorial] = useState(location.pathname.includes('tutoriais'));
  const [alertIconeBordero, setAlertIconeBordero] = useState(false);
  // const [qtdDocumentoReprovado, setQtdDocumentoReprovado] = useState(0);
  const { setMostraFundoEscuro } = props;
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const clienteSelecionado = getClienteAtual();
  const lstClientes = getClientes();
  const config = getConfigAmbiente();
  const logo =
    config && config.imagens && config.imagens.LOGO && config.imagens.LOGO !== 'image-default'
      ? config.imagens.LOGO
      : imageDefault;
  const corBaseCliente =
    config && config.cores && config.cores.CORBASECLIENTE ? config.cores.CORBASECLIENTE : '#666666';

  const onClickHandler = () => {
    document.body.classList.toggle('sidebar-mini');
    setSidebarMini(!sidebarMini);
    // setSidebarMiniTutorial(!sidebarMiniTutorial);
    if (onlySmallScreen && !document.body.classList.contains('sidebar-mini')) {
      setMostraFundoEscuro(true);
      aumentaZIndexFundo(true);
    } else {
      setMostraFundoEscuro(false);
      aumentaZIndexFundo(false);
    }
  };

  const aumentaZIndexFundo = tipo => {
    setTimeout(() => {
      let obj = document.getElementsByClassName('div-fundo-escuro');
      if (obj.length > 0) {
        if (tipo) {
          obj[0].className = 'div-fundo-escuro aumenta-z-index';
        }
      }
    }, '200');
  };

  const closeSidebar = () => {
    if (!document.body.classList.contains('sidebar-mini')) {
      document.body.classList.add('sidebar-mini');
    }
    setSidebarMini(!sidebarMini);
    // setSidebarMiniTutorial(!sidebarMiniTutorial);
    setMostraFundoEscuro(false);
  };

  useEffect(() => {
    if (sidebarMini) {
      setCollapse(false);
    } else if (!sidebarMini && location.pathname.includes('relatorios')) {
      setCollapse(true);
    }
  }, [location.pathname, sidebarMini]);

  // useEffect(() => {
  //   if (sidebarMiniTutorial) {
  //     setCollapseTutorial(false);
  //   } else if (!sidebarMiniTutorial && location.pathname.includes('tutoriais')) {
  //     setCollapseTutorial(true);
  //   }
  // }, [location.pathname, sidebarMiniTutorial]);

  useEffect(() => {
    if (
      clienteSelecionado &&
      clienteSelecionado.qtd_operacao_analise &&
      clienteSelecionado.qtd_operacao_analise > 0
    ) {
      setAlertIconeBordero(false);
    } else {
      setAlertIconeBordero(true);
    }
    // if (clienteSelecionado && clienteSelecionado.qtd_documento_reprovado) {
    //   setQtdDocumentoReprovado(clienteSelecionado.qtd_documento_reprovado);
    // }
  }, [props]);

  useEffect(() => {
    if (!onlySmallScreen) {
      if (setMostraFundoEscuro instanceof Function) {
        setMostraFundoEscuro(false);
      }
    }
  }, [onlySmallScreen]);

  return (
    <div className="sidebar">
      <style>
        {`
        .sidebar .nav li > a.active .sidebar-icon {
          color: ${corBaseCliente} !important;
        }
        .btn-home.active svg,
        .btn-home:hover svg  {
          stroke: ${corBaseCliente} !important;
        }
        .sidebar .nav li > a.active, 
        .sidebar .nav li > a:hover,
        .sidebar .nav li > button.active, 
        .sidebar .nav li > button:hover {
          border-left-color: ${corBaseCliente} !important;
        }
        .sidebar .nav li > a.active p, 
        .sidebar .nav li > a:hover p,
        .sidebar .nav li > button.active p, 
        .sidebar .nav li > button:hover p {
          color: ${corBaseCliente} !important;
        }
        .sidebar .nav li > .sidebar-hover-icon.active svg path,
        .sidebar .nav li > .sidebar-hover-icon:hover svg path {
          fill: ${corBaseCliente} !important;
        }
      `}
      </style>
      <button
        className="sidebar-burger btn-icon btn-default btn btn-icon-changes"
        type="button"
        onClick={onClickHandler}
      >
        {/* <img src={menuIcon} className="btn btn-burger" alt="" /> */}
        <i className="icon-default fa fa-bars" />
        <i className="icon-hover fa fa-chevron-right" />
      </button>
      <div className="sidebar-wrapper d-flex flex-column">
        <button
          type="button"
          onClick={onClickHandler}
          className="sidebar-toggle btn btn-simple btnLogoSlide"
        >
          <div
            className="img-logo"
            style={{
              backgroundImage: `url(${logo})`
            }}
            alt="Logo empresa"
          />
        </button>
        <Nav className="scrowSlide">
          <>
            {escopoAcesso && escopoAcesso.plataforma_cliente.rota && (
              <>
                {escopoAcesso.plataforma_cliente.rota.home && (
                  <NavItem>
                    <NavLink
                      title="Home"
                      to="/home"
                      isActive={(_, { pathname }) => ['/', '/home'].includes(pathname)}
                      activeClassName="active"
                      className="btn-home"
                      onClick={closeSidebar}
                      exact={true}
                      tag={RRNavLink}
                    >
                      <i className="sidebar-icon">
                        <Home />
                      </i>
                      <p>Home</p>
                    </NavLink>
                  </NavItem>
                )}
                {escopoAcesso.plataforma_cliente.rota.dashboard && (
                  <NavItem>
                    <NavLink
                      title="Dashboard"
                      to="/dashboard"
                      isActive={(_, { pathname }) => ['/dashboard'].includes(pathname)}
                      activeClassName="active"
                      className="btn-danshboard sidebar-hover-icon"
                      onClick={closeSidebar}
                      exact={true}
                      tag={RRNavLink}
                    >
                      {/* COMENTADO POIS PODERÁ SER USADO POSTERIORMENTE */}
                      {/* <div className="icon-new-dashboard">Novo</div> */}
                      <i className="sidebar-icon">
                        <Dashboard />
                      </i>
                      <p>Dashboard</p>
                    </NavLink>
                  </NavItem>
                )}
                {lstClientes && lstClientes.length > 0 && (
                  <>
                    {escopoAcesso.plataforma_cliente.rota.borderos && (
                      <NavItem className="d-lg-block">
                        <NavLink
                          title="Borderôs"
                          to="/borderos"
                          isActive={(_, { pathname }) => ['/borderos'].includes(pathname)}
                          activeClassName="active"
                          className="btn-borderos sidebar-hover-icon"
                          onClick={closeSidebar}
                          tag={RRNavLink}
                        >
                          <i className="sidebar-icon">
                            <div className={alertIconeBordero ? 'icone-alert-bordero' : ''} />
                            <Borderos />
                          </i>
                          <p>Borderôs</p>
                        </NavLink>
                      </NavItem>
                    )}
                    {escopoAcesso.plataforma_cliente.rota.instrucoes && (
                      <NavItem className="d-lg-block">
                        <NavLink
                          title="Instruções"
                          to="/instrucoes"
                          isActive={(_, { pathname }) => ['/instrucoes'].includes(pathname)}
                          activeClassName="active"
                          className="btn-intrucoes sidebar-hover-icon"
                          onClick={closeSidebar}
                          tag={RRNavLink}
                        >
                          <i className="sidebar-icon">
                            <Instrucoes />
                          </i>
                          <p>Instruções</p>
                        </NavLink>
                      </NavItem>
                    )}
                    {escopoAcesso.plataforma_cliente.rota.relatorios && (
                      <NavItem className="d-lg-block">
                        <Button
                          aria-expanded={collapse}
                          title="Relatórios"
                          className={`btn collapse-toggle btn-relatorios ${
                            collapse ? 'active' : ''
                          } sidebar-hover-icon`}
                          onClick={e => {
                            e.preventDefault();
                            if (sidebarMini) {
                              onClickHandler();
                              setCollapse(true);
                            } else {
                              setCollapse(!collapse && !sidebarMini);
                            }
                          }}
                        >
                          <i className="sidebar-icon">
                            <Relatorios />
                          </i>
                          <p>
                            Relatórios
                            <i className={`fa ${collapse ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                          </p>
                        </Button>
                        <Collapse isOpen={collapse} className="relatorio p-0">
                          <Nav className="mt-0">
                            {escopoAcesso.plataforma_cliente.rota['relatorios.borderos'] && (
                              <NavItem>
                                <NavLink
                                  title="Borderôs"
                                  to="/relatorios/borderos"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Borderôs</p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['relatorios.titulos'] && (
                              <NavItem>
                                <NavLink
                                  title="Títulos"
                                  to="/relatorios/titulos"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Títulos</p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['relatorios.concentracao-sacados'] && (
                              <NavItem>
                                <NavLink
                                  to="/relatorios/concentracao-sacados"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Concen. Sacados</p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['relatorios.debitos'] && (
                              <NavItem>
                                <NavLink
                                  title="Débitos"
                                  to="/relatorios/debitos"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Débitos</p>
                                </NavLink>
                              </NavItem>
                            )}
                          </Nav>
                        </Collapse>
                      </NavItem>
                    )}
                    {clienteSelecionado && clienteSelecionado.id !== 'todos' && (
                      <>
                        {escopoAcesso.plataforma_cliente.rota.retornos && (
                          <NavItem className="d-lg-block">
                            <NavLink
                              title="Retorno"
                              to="/retornos"
                              activeClassName="active"
                              className="btn-retornos sidebar-hover-icon"
                              onClick={closeSidebar}
                              tag={RRNavLink}
                            >
                              <i className="sidebar-icon">
                                <Retorno />
                              </i>
                              <p>Retornos</p>
                            </NavLink>
                          </NavItem>
                        )}
                        {/* <NavItem className="d-lg-block">
                        <NavLink
                          to="/documentos-cliente"
                          activeClassName="active"
                          className="btn-documentos-cliente"
                          onClick={closeSidebar}
                          tag={RRNavLink}
                          title={
                            qtdDocumentoReprovado > 0
                              ? qtdDocumentoReprovado > 1
                                ? `Existem ${qtdDocumentoReprovado} documentos que precisam ser atualizados`
                                : `Existe 1 documento que precisa ser atualizado`
                              : 'Documentos'
                          }
                        >
                          <i className="sidebar-icon sidebar-icon-documentos">
                            <div className={qtdDocumentoReprovado > 0 ? 'icone-alert-bordero' : ''} />
                            <FolderOutlinedIcon />
                          </i>
                          <p>Documentos</p>
                        </NavLink>
                      </NavItem> */}
                      </>
                    )}
                    {/* {escopoAcesso.plataforma_cliente.rota.tutoriais && (
                      <NavItem className="d-lg-block">
                        <Button
                          aria-expanded={collapseTutorial}
                          title="Tutoriais"
                          className={`btn collapse-toggle btn-tutoriais ${
                            collapseTutorial ? 'active' : ''
                          }`}
                          onClick={e => {
                            e.preventDefault();
                            if (sidebarMiniTutorial) {
                              onClickHandler();
                              setCollapseTutorial(true);
                            } else {
                              setCollapseTutorial(!collapseTutorial && !sidebarMiniTutorial);
                            }
                          }}
                        >
                          <i className="sidebar-icon sidebar-icon-tutorial">
                            <OndemandVideoOutlinedIcon />
                          </i>
                          <p>
                            Tutoriais
                            <i
                              className={`fa ${collapseTutorial ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                            />
                          </p>
                        </Button>
                        <Collapse isOpen={collapseTutorial} className="Relatorio p-0">
                          <Nav className="mt-0">
                            {escopoAcesso.plataforma_cliente.rota['tutoriais.entrada-xml'] && (
                              <NavItem>
                                <NavLink
                                  title="Entrada FIDC com XML"
                                  to="/tutoriais/entrada-xml"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>
                                    Entrada com XML
                                    <b className="font-size9"> (Novo)</b>
                                  </p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['tutoriais.entrada-cnab'] && (
                              <NavItem>
                                <NavLink
                                  title="Entrada FIDC com CNAB"
                                  to="/tutoriais/entrada-cnab"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>
                                    Entrada com CNAB
                                    <b className="font-size9"> (Novo)</b>
                                  </p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota[
                              'tutoriais.entrada-carta-correcao'
                            ] && (
                              <NavItem>
                                <NavLink
                                  title="Entrada manual no FIDC com Carta Correção"
                                  to="/tutoriais/entrada-carta-correcao"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>
                                    Entrada Manual
                                    <b className="font-size9"> (Novo)</b>
                                  </p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['tutoriais.dashboard'] && (
                              <NavItem>
                                <NavLink
                                  title="Dashboard"
                                  to="/tutoriais/dashboard"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Dashboard</p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['tutoriais.borderos'] && (
                              <NavItem>
                                <NavLink
                                  title="Borderôs"
                                  to="/tutoriais/borderos"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Borderôs</p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['tutoriais.instrucoes'] && (
                              <NavItem>
                                <NavLink
                                  title="Instruções"
                                  to="/tutoriais/instrucoes"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Instruções</p>
                                </NavLink>
                              </NavItem>
                            )}
                            {escopoAcesso.plataforma_cliente.rota['tutoriais.relatorios'] && (
                              <NavItem>
                                <NavLink
                                  title="Relatórios"
                                  to="/tutoriais/relatorios"
                                  activeClassName="active"
                                  onClick={closeSidebar}
                                  exact={true}
                                  tag={RRNavLink}
                                >
                                  <p>Relatórios</p>
                                </NavLink>
                              </NavItem>
                            )}
                          </Nav>
                        </Collapse>
                      </NavItem>
                    )} */}
                  </>
                )}
              </>
            )}

            {escopoAcesso && escopoAcesso.plataforma_crm && (
              <>
                {escopoAcesso.plataforma_crm.rota.dashboard && (
                  <NavItem>
                    <NavLink
                      title="Plataforma CRM"
                      to="/plataforma_crm/dashboard"
                      activeClassName="active"
                      onClick={closeSidebar}
                      exact={true}
                      tag={RRNavLink}
                      style={{ padding: '10px 0 10px 23px' }}
                      className="sidebar-hover-icon"
                    >
                      <i style={{ marginRight: '10px' }}>
                        <PlataformaCrmIcon />
                      </i>
                      <p>CRM</p>
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}

            {escopoAcesso && escopoAcesso.plataforma_formalizacao && (
              <>
                {escopoAcesso.plataforma_formalizacao.rota.dashboard && (
                  <NavItem>
                    <NavLink
                      title="Plataforma Formalização"
                      to="/plataforma_formalizacao/dashboard"
                      activeClassName="active"
                      onClick={closeSidebar}
                      exact={true}
                      tag={RRNavLink}
                      style={{ padding: '10px 0 10px 23px' }}
                      className="sidebar-hover-icon"
                    >
                      <i style={{ marginRight: '10px' }}>
                        <ContentPasteIcon />
                      </i>
                      <p>Formalização</p>
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}

            {escopoAcesso && escopoAcesso.plataforma_relacionamento && (
              <>
                {escopoAcesso.plataforma_relacionamento.rota.dashboard && (
                  <NavItem>
                    <NavLink
                      title="Plataforma Relacionamentos"
                      to="/plataforma_relacionamento/dashboard"
                      activeClassName="active"
                      onClick={closeSidebar}
                      exact={true}
                      tag={RRNavLink}
                      style={{ padding: '10px 0 10px 23px' }}
                      className="sidebar-hover-icon"
                    >
                      <i style={{ marginRight: '10px' }}>
                        <RelacionamentosCrm />
                      </i>
                      <p>Relacionamentos</p>
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
          </>
        </Nav>
      </div>
    </div>
  );
};

export default memo(Sidebar);
