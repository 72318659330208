import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  DialogContentText,
  DialogContent,
  Dialog
} from '@mui/material';
import { NavLink, useHistory } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import DescriptionIcon from '@mui/icons-material/Description';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Main from '../Main/Main';
import imagemCheck from '../../assets/img/bgCheck.jpg';
import imagemContato from '../../assets/img/contato_new.png';
import imagemParceria from '../../assets/img/parceria_new.png';
import imagemDocumentacao from '../../assets/img/documentacao_new.png';
import imagemSeguranca from '../../assets/img/seguranca_new.png';
import { getClienteAtual, setClienteAtual, getConfigAmbiente } from '../../shared/services/User';
import {
  getAnaliseOperacao,
  getDocumentosReprovadosCliente,
  getLimitesDisponiveisCliente,
  getTitulosPendenciasCliente
} from '../../api/clientSearch';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import ImagemJornadaCredito from '../../assets/img/img_fundo_one7_mais_credito.png';
import { getEstadoOne7MaisCredito } from '../../api/one7MaisCredito';

export default props => {
  const history = useHistory();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isPendingLimites, setPendingLimites] = useState(false);
  const [isPendingPendencias, setPendingPendencias] = useState(false);
  const [qtdDocumentoReprovado, setQtdDocumentoReprovado] = useState(0);
  const [tipoGarantia, setTipoGarantia] = useState(false);
  const [statusSolicitacaoCredito, setStatusSolicitacaoCredito] = useState(false);
  const [mostraJornadaOne7MaisCredito, setMostraJornadaOne7MaisCredito] = useState(false);
  const [dataInclusaoJornadaOne7MaisCredito, setDataInclusaoJornadaOne7MaisCredito] = useState('');
  const [dataFinalizacaoJornadaOne7MaisCredito, setDataFinalizacaoJornadaOne7MaisCredito] = useState('');
  const [isPendingSolicitacaoCredito, setPendingSolicitacaoCredito] = useState(false);
  const clienteSelecionado = getClienteAtual();

  const [limiteDisponiveis, setLimiteDisponiveis] = useState([]);
  const [lstTitulosPendencias, setLstTitulosPendencias] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const config = getConfigAmbiente();
  const corBaseCliente =
    config && config.cores && config.cores.CORBASECLIENTE ? config.cores.CORBASECLIENTE : '#666666';

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const irParaJornadaCredito = () => {
    history.push({
      pathname: '/jornada-credito',
      state: { novaJornadaCredito: true }
    });
  };

  const abrirTela = tela => {
    if (tela === 'retornos' && clienteSelecionado.id === 'todos') {
      setOpenAlert(true);
      return;
    }

    history.push({
      pathname: `/${tela}`
    });
  };

  const valorTotalLimite =
    limiteDisponiveis && Object.keys(limiteDisponiveis).length > 0
      ? limiteDisponiveis.map(item => item.DISPONIVEL).reduce((acc, curr) => acc + curr)
      : 0;

  useEffect(() => {
    if (clienteSelecionado && clienteSelecionado.cnpj !== null && clienteSelecionado.id !== 'todos') {
      getAnaliseOperacao(clienteSelecionado.cnpj).then(retorno => {
        let dados = retorno.data;
        if (Object.keys(dados).length > 0) {
          setClienteAtual({
            ...clienteSelecionado,
            qtd_operacao_analise: dados.qtd_operacao_analise
          });
        }
      });

      if (clienteSelecionado.id !== 'todos') {
        buscaLimitesCliente();

        buscaTitulosPendencias();

        getDocumentosReprovadosCliente(clienteSelecionado.cnpj).then(retorno => {
          let dados = retorno.data;
          if (typeof dados.qtd_documento_reprovado !== 'undefined') {
            setClienteAtual({
              ...clienteSelecionado,
              qtd_documento_reprovado: dados.qtd_documento_reprovado
            });

            setQtdDocumentoReprovado(dados.qtd_documento_reprovado);
          }
        });

        setPendingSolicitacaoCredito(true);
        // deabilitando o banner One7MaisCredito
        setTipoGarantia(false);
        setStatusSolicitacaoCredito(false);
        setMostraJornadaOne7MaisCredito(false);
        setDataInclusaoJornadaOne7MaisCredito('');
        setDataFinalizacaoJornadaOne7MaisCredito('');
        // deabilitando o banner One7MaisCredito
        getEstadoOne7MaisCredito(clienteSelecionado.cnpj)
          .then(retorno => {
            if (Object.keys(retorno.data).length > 0) {
              if (
                retorno.data.TITULOSEMABERTO === 'N' &&
                (retorno.data.BLOQUEADO === 'S' ||
                  retorno.data.PROPOSTAVENCIDA ||
                  retorno.data.ESTADOPPC === null ||
                  (retorno.data.STATUSANALISE && retorno.data.STATUSANALISE === 'D'))
              ) {
                setMostraJornadaOne7MaisCredito(true);
              } else {
                setMostraJornadaOne7MaisCredito(false);

                setDataInclusaoJornadaOne7MaisCredito(retorno.data.DATAINCLUSAO);
                setTipoGarantia(retorno.data.TIPOGARANTIA);

                if (retorno.data.SITUACAO === '' || retorno.data.SITUACAO === null) {
                  setStatusSolicitacaoCredito(true);
                } else {
                  setStatusSolicitacaoCredito(retorno.data.SITUACAO);
                  setDataFinalizacaoJornadaOne7MaisCredito(retorno.data.DATAFINALIZACAO);
                }
              }
            } else {
              // IRA MOSTRAR O BANNER POIS NAO EXISTE NENHUMA PPC ONE7MAISCREDITO
              setMostraJornadaOne7MaisCredito(true);
              setStatusSolicitacaoCredito(false);
            }
          })
          .catch(() => {
            // sem acesso ao produto one7Mais Credito
          })
          .finally(() => {
            setPendingSolicitacaoCredito(false);
          });
      }
    }
  }, []);

  const buscaLimitesCliente = () => {
    setPendingLimites(true);
    setLimiteDisponiveis([]);
    getLimitesDisponiveisCliente()
      .then(retorno => {
        let dados = retorno.data;
        if (Object.keys(dados).length > 0) {
          setLimiteDisponiveis(dados);
        }
      })
      .finally(() => {
        setPendingLimites(false);
      });
  };

  const buscaTitulosPendencias = () => {
    setPendingPendencias(true);
    setLstTitulosPendencias([]);
    getTitulosPendenciasCliente()
      .then(retorno => {
        let dados = retorno.data;
        if (Object.keys(dados).length > 0) {
          setLstTitulosPendencias(dados);
        }
      })
      .finally(() => {
        setPendingPendencias(false);
      });
  };

  return (
    <Main {...props}>
      <Card sx={{ background: 'transparent', boxShadow: 'none' }}>
        <CardContent sx={{ padding: '0px' }}>
          <Box className="mt-2">
            <Grid container spacing={3}>
              {clienteSelecionado.id !== 'todos' && mostraJornadaOne7MaisCredito && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    className="card-jornada-credito"
                    component="div"
                    sx={{
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${ImagemJornadaCredito})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      minHeight: '160px',
                      borderRadius: '8px',
                      float: 'left'
                    }}
                  >
                    <Box className="sub-card-jornada-credito">
                      <Typography
                        variant="h5"
                        className="mt-3 texto-cor-complementar texto-esquerda texto-negrito"
                      >
                        Capital de giro para sua empresa
                      </Typography>
                      <Typography variant="subtitle1" className="mt-2 texto-cor-branca">
                        Contratação simples e totalmente digital
                      </Typography>
                      <Button className="mt-3 btn-solicitar" onClick={() => irParaJornadaCredito()}>
                        {onlySmallScreen ? 'Solicitar análise' : 'Solicitar análise de crédito'}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )}

              {clienteSelecionado.id !== 'todos' && (
                <>
                  {statusSolicitacaoCredito && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Card
                        className="border-box-home"
                        sx={isPendingSolicitacaoCredito ? { position: 'relative' } : {}}
                      >
                        {isPendingSolicitacaoCredito && (
                          <LoaderMain tipoLoader="float" tipoImagem="circulo" />
                        )}
                        <Box
                          className="solicitacao-analise-credito"
                          sx={{ margin: '10px 15px 5px 15px' }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <div className="flex-box items-centro-verticalmente">
                                <div className="icone-home-solicitacao">
                                  <AccessTimeIcon />
                                </div>
                                <Typography
                                  variant="subtitle1"
                                  className="texto-cor-cinza-escuro texto-esquerda margin-left-15"
                                >
                                  Acompanhe sua solicitação de
                                  {tipoGarantia === 'IM' ? (
                                    <b> Crédito com garantia de imóvel.</b>
                                  ) : (
                                    <b> Crédito com garantia aval.</b>
                                  )}
                                </Typography>
                              </div>

                              <div>
                                <Typography
                                  variant="subtitle1"
                                  className="mt-2 texto-cor-cinza-escuro texto-esquerda"
                                >
                                  <span>Envio da proposta em </span>
                                  <span>{dataInclusaoJornadaOne7MaisCredito}</span>
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <Typography
                                variant="subtitle1"
                                className="texto-cor-cinza-escuro texto-direita mt-2"
                              >
                                <>
                                  {statusSolicitacaoCredito === 'D' ? (
                                    <span className="status-solicitacao status-solicitacao-deferida">
                                      Deferida
                                    </span>
                                  ) : statusSolicitacaoCredito === 'I' ? (
                                    <span className="status-solicitacao status-solicitacao-indeferida">
                                      Indeferida
                                    </span>
                                  ) : (
                                    <span className="status-solicitacao status-solicitacao-em-analise">
                                      Em análise
                                    </span>
                                  )}
                                </>
                              </Typography>

                              {(statusSolicitacaoCredito === 'D' ||
                                statusSolicitacaoCredito === 'I') && (
                                <Typography
                                  variant="subtitle1"
                                  className="texto-cor-cinza-escuro texto-direita mt-3"
                                >
                                  <span>Concluída em </span>
                                  <span>{dataFinalizacaoJornadaOne7MaisCredito}</span>
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card
                      className="box-shadow-itens-home"
                      sx={
                        isPendingLimites
                          ? { position: 'relative', minHeight: '205px' }
                          : { minHeight: '205px' }
                      }
                    >
                      {isPendingLimites && (
                        <LoaderMain tipoLoader="float" percentTop="45%" tipoImagem="circulo" />
                      )}

                      <div className="margin-15">
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="flex-box">
                              <i>
                                <CheckCircleIcon className="icone-limite-disponivel pulse-animation green" />
                              </i>
                              <Typography
                                variant="subtitle1"
                                className="texto-cor-cinza-escuro texto-esquerda margin-left-15 font-bold"
                              >
                                Limite disponível
                              </Typography>
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="texto-direita">
                            <NavLink
                              title="Mais informações"
                              to="/dashboard"
                              activeClassName="active"
                              className="font-size-15 texto-link"
                              exact={true}
                            >
                              Mais informações
                            </NavLink>
                          </Grid>
                        </Grid>

                        <div className="min-height-85">
                          <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <Box
                                className="flex-box items-centro-verticalmente"
                                sx={{ minHeight: '135px;' }}
                              >
                                <Box>
                                  <Box className="texto-cor-cinza-escuro texto-esquerda font-size-18">
                                    Total
                                  </Box>
                                  <Box className="texto-cor-cinza-escuro texto-esquerda font-size-18 font-bold">
                                    <span>R$ </span>
                                    {valorTotalLimite && valorTotalLimite > 0
                                      ? valorTotalLimite.toLocaleString('pt-br', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })
                                      : '-'}
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                minHeight: '120px',
                                paddingTop: '5px'
                              }}
                            >
                              {limiteDisponiveis && Object.keys(limiteDisponiveis).length > 0 && (
                                <Box>
                                  {limiteDisponiveis.map(dados => (
                                    <Box key={dados.CODPRODUTO} sx={{ marginTop: '20px' }}>
                                      <span className="texto-cor-cinza-escuro texto-esquerda font-size-16 camel-case">
                                        {dados.PRODUTO.toLowerCase()}
                                      </span>
                                      <span> &nbsp; </span>
                                      <span
                                        className={`${
                                          dados.DISPONIVEL > 0
                                            ? 'texto-cor-cinza-escuro'
                                            : 'texto-danger'
                                        }  texto-esquerda font-size-16 font-bold`}
                                      >
                                        <span>R$ </span>
                                        {parseFloat(dados.DISPONIVEL).toLocaleString('pt-br', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
                                      </span>
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card
                      className="box-shadow-itens-home"
                      sx={
                        isPendingPendencias
                          ? { position: 'relative', minHeight: '205px' }
                          : { minHeight: '205px' }
                      }
                    >
                      {isPendingPendencias && (
                        <LoaderMain tipoLoader="float" percentTop="45%" tipoImagem="circulo" />
                      )}

                      <div className="margin-15">
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div className="flex-box">
                              <i>
                                <WarningOutlinedIcon className="icone-titulos-pendencias pulse-animation yellow" />
                              </i>
                              <Typography
                                variant="subtitle1"
                                className="texto-cor-cinza-escuro texto-esquerda margin-left-15 font-bold"
                              >
                                Títulos com pendências
                              </Typography>
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="texto-direita">
                            <NavLink
                              title="Ver títulos com pendências"
                              to="/instrucoes/incluir-nova?tipoInstrucao=1"
                              activeClassName="active"
                              className="font-size-15 texto-link"
                              exact={true}
                            >
                              Ver todos os títulos
                            </NavLink>
                          </Grid>
                        </Grid>

                        <div className="min-height-85">
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              {lstTitulosPendencias && Object.keys(lstTitulosPendencias).length > 0 ? (
                                <Box className="mt-4 overflow-x">
                                  <Grid container sx={{ minWidth: '563px' }}>
                                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                      <span className="texto-cor-cinza-escuro texto-esquerda font-size-16 font-bold">
                                        Sacado
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      className="texto-centro"
                                    >
                                      <span className="texto-cor-cinza-escuro texto-esquerda font-size-16 font-bold">
                                        Nº do doc.
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      className="texto-centro"
                                    >
                                      <span className="texto-cor-cinza-escuro texto-esquerda font-size-16 font-bold">
                                        Vencimento
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      className="texto-centro"
                                    >
                                      <span className="texto-cor-cinza-escuro texto-esquerda font-size-16 font-bold">
                                        Valor
                                      </span>
                                    </Grid>
                                  </Grid>
                                  {lstTitulosPendencias.map(dados => (
                                    <Grid
                                      container
                                      key={dados.codTitulo}
                                      className="mt-2"
                                      sx={{ minWidth: '563px' }}
                                    >
                                      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                        <Box className="row-titulos-com-pendencias texto-nowrap-overflow-dotted pad-right-left-10">
                                          <span className="texto-cor-cinza-escuro texto-esquerda font-size-16">
                                            {dados.nomeSacado}
                                          </span>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        className="texto-centro"
                                      >
                                        <Box className="row-titulos-com-pendencias texto-nowrap-overflow-dotted">
                                          <span
                                            className="texto-cor-cinza-escuro texto-esquerda font-size-15"
                                            title={dados.nDoc}
                                          >
                                            {dados.nDoc}
                                          </span>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                        className="texto-centro"
                                      >
                                        <Box className="row-titulos-com-pendencias">
                                          <span className="texto-cor-principal texto-esquerda font-size-15 font-bold">
                                            {dados.dataVencimento}
                                          </span>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        className="texto-direita"
                                      >
                                        <Box className="row-titulos-com-pendencias">
                                          <span className="texto-cor-cinza-escuro texto-esquerda font-size-16">
                                            <span>R$ </span>
                                            {parseFloat(dados.valorTitulo).toLocaleString('pt-br', {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })}
                                          </span>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Box>
                              ) : (
                                <Box
                                  className="row-titulos-sem-pendencias mt-3"
                                  sx={{
                                    background: `url(${imagemCheck})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                  }}
                                >
                                  <span className="texto-cor-cinza-escuro font-size-16">
                                    Tudo certo por aqui! Você não tem nenhum título com pendência no
                                    momento.
                                  </span>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <hr className="hr-divisoria-home" />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Grid
                  container
                  className="cursor-pointer card-shadow-hover"
                  onClick={() => abrirTela('borderos')}
                  sx={{ background: 'white', borderRadius: '8px' }}
                >
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box
                      className="itens-card-home"
                      sx={{ backgroundColor: `${corBaseCliente} !important` }}
                    >
                      <i>
                        <CurrencyExchangeIcon sx={{ fontSize: '25px' }} />
                      </i>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Box className="font-size-16 margin-left-10 pad-topbot-5 itens-card-titulo-home">
                      Borderôs
                    </Box>
                    <Box className="font-size-14 margin-left-10 pad-topbot-5 itens-card-texto-home">
                      Acompanhe e envie suas operações de crédito
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box className="itens-card-home-chevron">
                      <i>
                        <ChevronRightIcon />
                      </i>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Grid
                  container
                  className="cursor-pointer card-shadow-hover"
                  onClick={() => abrirTela('instrucoes')}
                  sx={{ background: 'white', borderRadius: '8px' }}
                >
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box
                      className="itens-card-home"
                      sx={{ backgroundColor: `${corBaseCliente} !important` }}
                    >
                      <i>
                        <DriveFileRenameOutlineIcon sx={{ fontSize: '30px' }} />
                      </i>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Box className="font-size-16 margin-left-10 pad-topbot-5 itens-card-titulo-home">
                      Instruções
                    </Box>
                    <Box className="font-size-14 margin-left-10 pad-topbot-5 itens-card-texto-home">
                      Recompra, abatimento ou prorrogação de títulos
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box className="itens-card-home-chevron">
                      <i>
                        <ChevronRightIcon />
                      </i>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Grid
                  container
                  className="cursor-pointer card-shadow-hover"
                  onClick={() => abrirTela('relatorios/titulos')}
                  sx={{ background: 'white', borderRadius: '8px' }}
                >
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box
                      className="itens-card-home"
                      sx={{ backgroundColor: `${corBaseCliente} !important` }}
                    >
                      <i>
                        <DescriptionIcon sx={{ fontSize: '28px' }} />
                      </i>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Box className="font-size-16 margin-left-10 pad-topbot-5 itens-card-titulo-home">
                      Relatórios
                    </Box>
                    <Box className="font-size-14 margin-left-10 pad-topbot-5 itens-card-texto-home">
                      De títulos, borderôs, conc. de sacados ou débitos
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box className="itens-card-home-chevron">
                      <i>
                        <ChevronRightIcon />
                      </i>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Grid
                  container
                  className="cursor-pointer card-shadow-hover"
                  onClick={() => abrirTela('retornos')}
                  sx={{ background: 'white', borderRadius: '8px' }}
                >
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box
                      className="itens-card-home"
                      sx={{ backgroundColor: `${corBaseCliente} !important` }}
                    >
                      <i>
                        <ReadMoreIcon sx={{ fontSize: '35px' }} />
                      </i>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Box className="font-size-16 margin-left-10 pad-topbot-5 itens-card-titulo-home">
                      Retornos
                    </Box>
                    <Box className="font-size-14 margin-left-10 pad-topbot-5 itens-card-texto-home">
                      De dados sobre operações financeiras processadas
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Box className="itens-card-home-chevron">
                      <i>
                        <ChevronRightIcon />
                      </i>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Box>
                  <img src={imagemContato} alt="Contato" className="w-100" draggable="false" />
                </Box>
                <Box className="img-itens-home">
                  <Box className="pad-5 margin-left-10 font-size-16 pad-top-10 font-bold">
                    Fale conosco
                  </Box>
                  <Box className="pad-5 margin-left-10 font-size-16 mt-2">
                    Precisa de ajuda? Estamos disponíveis pelo chat ou telefone para suporte rápido e
                    eficiente.
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Box>
                  <img src={imagemDocumentacao} alt="Contato" className="w-100" draggable="false" />
                </Box>
                <Box className="img-itens-home">
                  <Box className="pad-5 margin-left-10 font-size-16 pad-top-10 font-bold">
                    Atualização de documentos
                  </Box>
                  <Box className="pad-5 margin-left-10 font-size-16 mt-2">
                    {clienteSelecionado.id !== 'todos' && qtdDocumentoReprovado > 0 ? (
                      <span>
                        {qtdDocumentoReprovado > 1 ? 'Existem' : 'Existe'}
                        &nbsp;
                        {qtdDocumentoReprovado}
                        &nbsp;
                        {qtdDocumentoReprovado > 1
                          ? 'documentos que precisam ser atualizados'
                          : 'documento que precisa ser atualizado'}
                        Contate seu gerente.
                      </span>
                    ) : (
                      <span>
                        Para garantir conformidade, verifique e atualize seus documentos quando
                        necessário.
                      </span>
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Box>
                  <img src={imagemSeguranca} alt="Contato" className="w-100" draggable="false" />
                </Box>
                <Box className="img-itens-home">
                  <Box className="pad-5 margin-left-10 font-size-16 pad-top-10 font-bold">
                    Segurança de dados
                  </Box>
                  <Box className="pad-5 margin-left-10 font-size-16 mt-2">
                    Utilizamos práticas de segurança rigorosas para proteger suas informações.
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <Box>
                  <img src={imagemParceria} alt="Parceria" className="w-100" draggable="false" />
                </Box>
                <Box className="img-itens-home">
                  <Box className="pad-5 margin-left-10 font-size-16 pad-top-10 font-bold">
                    Amplie seu negócio
                  </Box>
                  <Box className="pad-5 margin-left-10 font-size-16 mt-2">
                    Descubra nossas soluções para ajudar sua empresa e crescer com confiança
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Dialog open={openAlert} onClose={handleCloseAlert}>
            <DialogContent>
              <DialogContentText>
                Você precisa selecionar uma empresa para acessar a página de retornos.
              </DialogContentText>
            </DialogContent>
            <Box className="texto-centro mb-2">
              <Button className="texto-cor-cinza-escuro camel-case" onClick={handleCloseAlert}>
                Fechar
              </Button>
            </Box>
          </Dialog>
        </CardContent>
      </Card>
    </Main>
  );
};
